import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Helpers } from '../shared/helpers';
import { tap } from 'rxjs/operators';
import { forgetPasswordService } from '../forget-password/forget-password.service';


@Component({
  selector: 'app-generate-password',
  templateUrl: './generate-password.component.html',
  styleUrls: ['./generate-password.component.scss']
})
export class GeneratePasswordComponent implements OnInit {

  loadPage = new BehaviorSubject('waiting');
  _loadPage = this.loadPage.asObservable();

  form: FormGroup;

  showModalSuccess: boolean = false;
  load: string = '';
  params: any = {};

  identifiers: any = [];
  responseError: string;

  constructor( 
    private route: ActivatedRoute,
    private router: Router,
    public helpers: Helpers, 
    private fb: FormBuilder,
    private forgetPasswordService: forgetPasswordService,
  ) { }

  ngOnInit() {

    this.params = this.route.snapshot.params;

    this.form = this.fb.group({
      identifier: [null, Validators.required],
    });

    const hasNoIdentifier = () => {
      this.router.navigate(['']);
    };

    this.load = 'show';
    this.forgetPasswordService.getIdentificadores(this.params.cpf).pipe(
      tap(() => this.load = ''),
      tap(identifiers => identifiers.length === 0 ? hasNoIdentifier() : null)
    ).subscribe(identifiers => this.identifiers = identifiers);
  }

  ngAfterContentInit(){
    setTimeout(() => {
      this.loadPage.next('loaded');
    }, 200)
  }

  onSubmit() {
    this.load = 'show';
    this.forgetPasswordService.sendForgetPassword(this.params.cpf, this.form.get('identifier').value).pipe(
      tap(() => this.load = ''),
    ).subscribe((response: any) => {
      if (response && response.success) {
        this.showModalSuccess = true;
      }
    }, (err) => {
      this.responseError = "Ocorreu um erro, entre em contato com a central para mais informações";
    });
  }

  getIdentifierSelected() {
    return this.identifiers.find(identifier => identifier.option_id === this.form.get('identifier').value);
  }

  redirectToLogin() {
    this.router.navigate([`cadastro/${ this.params.cpf }`]);
  }

}
