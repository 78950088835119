import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { DzbuttonComponent } from './dzbutton/dzbutton.component';
import { StepBoxComponent } from './step-box/step-box.component';
import { LoadComponent } from './load/load.component';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ModalTokenComponent } from './modal-token/modal-token.component';
import { SimpleModalComponent } from './simple-modal/simple-modal.component';
import { RegulamentoComponent } from './regulamento/regulamento.component';
import { CountdownTimerModule } from '../components/countdown-timer/countdown-timer.module';


@NgModule({
  declarations: [
    DzbuttonComponent, 
    LoadComponent,
    StepBoxComponent,
    ModalTokenComponent,
    SimpleModalComponent,
    RegulamentoComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    CountdownTimerModule,
  ],
  exports: [
    DzbuttonComponent,
    LoadComponent,
    StepBoxComponent,
    ModalTokenComponent,
    SimpleModalComponent,
    RegulamentoComponent
  ],
})
export class SharedModule { }
