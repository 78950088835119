<div style="visibility: hidden;" id="session-token">{{ sessionToken }}</div>
<div [@loadPage]="loadPage.getValue()">

  <ng-container *ngIf="params && params.cadastro">
    <h1>Cadastro  </h1>
    <p >Olá informe seu CPF/CNPJ para iniciar o cadastro</p>
  </ng-container>

  <ng-container *ngIf="params && !params.cadastro">
    <h1>Acesse sua conta</h1>
    <p >Olá informe alguns dados para acessar sua conta.</p>
  </ng-container>

  <div class="form">
    <form [formGroup]="firstStepForm" (ngSubmit)="onSubmit()" autocomplete="off">

      <!-- <div class="input-wrapper loginType">
        <span>Entrar como:</span>

        <div class="custom-radio">
          <input type="radio" name="loginType" id="cpf">
          <label for="cpf" (click)="changeLoginType('cpf')"></label>
          <label for="cpf" (click)="changeLoginType('cpf')">CPF</label>
        </div>
        <div class="custom-radio">
          <input type="radio" name="loginType" id="cnpj">
          <label for="cnpj" (click)="changeLoginType('cnpj')"></label>
          <label for="cnpj" (click)="changeLoginType('cnpj')">CNPJ</label>
        </div>
      </div> -->

      <div class="input-wrapper">
        <input type="text" name="cpfOrCNPJ" id="cpfOrCNPJ" formControlName="identifier" [placeholder]="placeholder" [mask]="inputMask" (ngModelChange)="verifyContract()" (ngModelChange)="master_change()">
        <small class="msg-error" *ngIf="firstStepForm.get('identifier').hasError('incorrect') && firstStepForm.get('identifier').touched">Documento inválido</small>
      </div>

      <div class="cnt-agremments">
        <div class="terms-accepts" *ngIf="showTermAcceptTerms">
          <input type="checkbox" formControlName="termsUse" name="terms" id="terms" class="input-check">
          <label for="terms"><span>Li e concordo com o <a href="http://privacidade.dotz.com.br/regulamento/regulamentoDotz" target="_blank"> Regulamento</a></span></label>
        </div>
        <div class="terms-accepts" *ngIf="showTermAcceptPrivacy">
          <input type="checkbox" formControlName="privacity" name="privacity" id="privacity" class="input-check">
          <label for="privacity"><span>Li e concordo com a <a href="http://privacidade.dotz.com.br/politicaPrivacidade" target="_blank"> Política de Privacidade</a></span></label>
        </div>
      </div>

      <div class="input-wrapper cta-wrapper">
        <app-btn type="submit" text="Entrar" color="orange-light" size="btn-retangle" [disabled]="(!testIdentifier(firstStepForm.get('identifier').value) || !checkedAggrements())"></app-btn>
      </div>

    </form>
  </div>
</div>
<app-load [option]="loading"></app-load>