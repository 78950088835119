import { environment } from 'src/environments/environment';
import { Component, OnInit, Output, EventEmitter, AfterContentInit, HostListener } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidatorsUtil } from 'dharma-ui-common';
import { Router, ActivatedRoute } from '@angular/router';

import { AnalyticsService, Geolocation } from './../../services/analytics.service';
import { Helpers } from '../../shared/helpers';
import { LoadPageAnimation } from 'src/app/shared/animations';
import { BehaviorSubject } from 'rxjs';
import * as Cookies from 'js-cookie';

import { SignupService } from './../../services/signup.service';
@Component({
  selector: 'app-first-step',
  templateUrl: './first-step.component.html',
  styleUrls: ['./first-step.component.scss'],
  animations: [
    LoadPageAnimation
  ]
})
export class FirstStepComponent implements OnInit, AfterContentInit {

  firstStepForm: FormGroup;
  queryParams: any = {};
  params: any = {};

  loadPage = new BehaviorSubject('waiting');
  _loadPage = this.loadPage.asObservable();

  @Output() response = new EventEmitter();

  inputMask: string;
  placeholder: string = 'Informe o seu CPF/CNPJ';
  loginType: string = '';
  sessionToken: string;
  showTermAccept: boolean = false;
  agreementsTerms: Array<string> = [];
  showTermAcceptTerms: boolean;
  showTermAcceptPrivacy: boolean;
  regulamento: boolean;
  politica: boolean;
  loading = '';
  agreements: any;

  constructor(
    private router: Router,
    private helpers: Helpers,
    private route: ActivatedRoute,
    private signupService: SignupService,
    private analytics: AnalyticsService,
  ) { }

  ngOnInit() {

    this.params = this.route.snapshot.params;
    this.queryParams = this.route.snapshot.queryParams;
    this.sessionToken = Cookies.get('dotzSessionToken');
    window.parent.postMessage(
      {
        event_id: 'dotz-session-token',
        data: {
          sessionToken: this.sessionToken,
        }
      },
      '*'
    );
    window.addEventListener('message', (event: any) => {
      if (!event || !event.data) { return; }
      const object = event.data;
      if (object && object.event_id === 'remove-dotz-session-token') {
        Cookies.remove('dotzSessionToken');
      }
    });
    if (this.queryParams.originurl !== null && this.queryParams.originurl !== undefined) {
      localStorage.setItem('rota', this.queryParams.originurl);
    }

    this.firstStepForm = new FormGroup({
      identifier: new FormControl('', [Validators.required, Validators.minLength(11), Validators.maxLength(14)]),
      termsUse: new FormControl(false),
      privacity: new FormControl(false)
    });

    this.firstStepForm.get('identifier').valueChanges.subscribe(() => {
      this.returnMask();
    });
    this.getContractId()
  }

  returnMask() {
    const formControlValue = this.firstStepForm.get('identifier').value;
    if (formControlValue.length > 11) {
      this.inputMask = '00.000.000/0000-00';
    } else {
      this.inputMask = '000.000.000-000';
    }
  }

  getContractId() {
    this.signupService.getContract(environment.TERM_CODE_POLICTS_1).subscribe(response => {
      this.agreementsTerms.push(response.data.contractId)
    })
    this.signupService.getContract(environment.TERM_CODE_USE_1).subscribe(response => {
      this.agreementsTerms.push(response.data.contractId)
    })
  }

  verifyContract(): void {


    const { identifier } = this.firstStepForm.value;

    const documentLength: Array<number> = [11, 14];
    const notTermAcept: Array<any> = [];
    let resultObjTo: Array<any> = [];
    let regulamento = false;
    let politica = false;

    document.getElementById("cpfOrCNPJ").removeAttribute("readonly");

    if (identifier.length < 11 || (identifier.length > 11 && identifier.length < 14)) {
      this.clearValidator();
      this.showTermAcceptTerms = false;
      this.showTermAcceptPrivacy = false;
    }

    if (this.testIdentifier(identifier)) {

      if (identifier.length == 11) {
        let buildURLToNewLogin = environment.NEW_LOGIN + '?identifier=' + identifier;
        const returnRoute = localStorage.getItem('urlAfterCallback');
        if (returnRoute) {
          buildURLToNewLogin = buildURLToNewLogin + '&urlAfterCallback=' + returnRoute;
        }
        window.open(buildURLToNewLogin, '_self');
      } else {
        if (documentLength.includes(identifier.length)) {

        this.loading = 'show';
        document.getElementById("cpfOrCNPJ").setAttribute("readonly", "true");

        var arrayCodes = [
          environment.TERM_CODE_USE_2,
          environment.TERM_CODE_USE_1,
          environment.TERM_CODE_POLICTS_3,
          environment.TERM_CODE_POLICTS_2,
          environment.TERM_CODE_POLICTS_1

        ];
        this.signupService.verifyContractArray(arrayCodes, identifier).then((result) => {
          if (result.success == true) {
            //Regulamento
            if (result.Data[environment.TERM_CODE_USE_1] == true || result.Data[environment.TERM_CODE_USE_2] == true) {
              this.regulamento = true;
              this.clearValidatorTerms();
              this.showTermAcceptTerms = false;
            } else {
              this.regulamento = false;
              this.showTermAcceptTerms = true;
              this.setValidatorTerms();
            }
            //Privacidade
            if (result.Data[environment.TERM_CODE_POLICTS_1] == true || result.Data[environment.TERM_CODE_POLICTS_2] == true || result.Data[environment.TERM_CODE_POLICTS_3] == true) {
              this.politica = true;
              this.showTermAcceptPrivacy = false;
              this.clearValidatorPrivacity();
            } else {
              this.politica = false;
              this.showTermAcceptPrivacy = true;
              this.setValidatorPrivacity();
            }
          } else {
            this.regulamento = false;
            this.politica = false;
            this.showTermAcceptPrivacy = true;
            this.setValidator();
            this.showTermAcceptTerms = true;
            this.setValidatorTerms();
          }
          this.loading = '';
          document.getElementById("cpfOrCNPJ").removeAttribute("readonly");
        }).catch(() => {
          this.regulamento = false;
          this.politica = false;
          this.showTermAcceptPrivacy = true;
          this.setValidator();
          this.showTermAcceptTerms = true;
          this.setValidatorTerms();
          this.loading = '';
          document.getElementById("cpfOrCNPJ").removeAttribute("readonly");

        });
      }
      }
    }
  }

  master_change() {
    this.firstStepForm.controls.privacity.setValue(false)
    this.firstStepForm.controls.termsUse.setValue(false)
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.loadPage.next('loaded');
    }, 200);

    if (this.params && this.params.cpf) {

      if (this.params.cpf.length == 11) {
        let buildURLToNewLogin = environment.NEW_LOGIN + '?identifier=' + this.params.cpf;
        const returnRoute = localStorage.getItem('urlAfterCallback');
        if (returnRoute) {
          buildURLToNewLogin = buildURLToNewLogin + '&urlAfterCallback=' + returnRoute;
        }
        window.open(buildURLToNewLogin, '_self');
      }

      this.firstStepForm.controls.identifier.setValue(this.params.cpf);
      this.onSubmit();
    }
  }

  setValidatorTerms() {
    this.firstStepForm.controls['termsUse'].setValidators(Validators.compose([ValidatorsUtil.required('Termos de uso')]))
  }

  setValidatorPrivacity() {
    this.firstStepForm.controls['privacity'].setValidators(Validators.compose([ValidatorsUtil.required('Política de Privacidade')]))
  }

  clearValidatorTerms() {
    this.firstStepForm.controls['termsUse'].clearValidators()
  }

  clearValidatorPrivacity() {
    this.firstStepForm.controls['privacity'].clearValidators()
  }

  setValidator() {
    this.firstStepForm.controls['termsUse'].setValidators(Validators.compose([ValidatorsUtil.required('Termos de uso')]))
    this.firstStepForm.controls['privacity'].setValidators(Validators.compose([ValidatorsUtil.required('Política de Privacidade')]))
  }

  clearValidator() {
    this.firstStepForm.controls['termsUse'].clearValidators()
    this.firstStepForm.controls['privacity'].clearValidators()
  }

  onSubmit() {
    const formControlValue = this.firstStepForm.get('identifier').value;
    if (!this.testIdentifier(formControlValue)) { return; }
    const agreements = this.agreementsTerms;
    if (this.showTermAcceptTerms || this.showTermAcceptPrivacy || (this.showTermAcceptPrivacy && this.showTermAcceptTerms)) {
      this.analytics.geolocation().subscribe((geoLocation: Geolocation) => {
        const platform = this.analytics.platform();
        const value: any = {
          agreements,
          platform: platform.osCode,
          modelPlatform: platform.modelPlatform,
          appVersion: platform.appVersion,
          ...geoLocation,
        };
        this.response.emit({
          form: this.firstStepForm.value,
          loginType: this.loginType,
          agreements: JSON.stringify(value)
        });
      }, (err) => {
        this.agreementsTerms = null;
      });
    }
    if (!this.showTermAcceptTerms && !this.showTermAcceptPrivacy) {
      this.response.emit({
        form: this.firstStepForm.value,
        loginType: this.loginType
      })
    }
  }

  testIdentifier(strCpfCnpj: string) {
    if (strCpfCnpj.length == 14) {
      return this.helpers.checkCNPJ(strCpfCnpj, this.firstStepForm);
    }
    if (strCpfCnpj.length == 11) {
      return this.helpers.checkCPF(strCpfCnpj, this.firstStepForm);
    }
  }

  checkedAggrements() {
    if (this.firstStepForm.controls.privacity.value && this.firstStepForm.controls.termsUse.value) {
      return this.firstStepForm.value.termsUse;
    }
    if (this.firstStepForm.controls.privacity.value && !this.showTermAcceptTerms) {
      return this.firstStepForm.value.privacity;
    }
    if (this.firstStepForm.controls.termsUse.value && !this.showTermAcceptPrivacy) {
      return this.firstStepForm.value.termsUse;
    }
    return this.politica == true && this.regulamento == true

    // if (this.politica == false && this.regulamento == false) {
    //   return true;
    // }
  }

}
