import { Component, OnInit, Output, EventEmitter, AfterContentInit, Input, ElementRef, ViewChild } from '@angular/core';
import { LoadPageAnimation } from 'src/app/shared/animations';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TransactionValidationService } from 'src/app/services/transaction-validation.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DeviceChannel } from 'src/app/shared/enum/device-channel';
import * as Cookies from 'js-cookie';
import { environment } from 'src/environments/environment';
import { SwalErrorService } from 'src/app/shared/swal-errors.service';
import { Router } from '@angular/router';
import { AlertService, AlertSummaryService } from 'dharma-ui-alerts';
import { CountdownTimerComponent } from 'dharma-ui-components';

@Component({
  selector: 'app-second-step-challenge',
  templateUrl: './second-step.component.html',
  styleUrls: ['./second-step.component.scss'],
  animations: [
    LoadPageAnimation
  ]
})
export class SecondStepChallengeComponent implements OnInit {

  @ViewChild('countdownTimer', { static: false }) countdownTimerComponent: CountdownTimerComponent;

  @Input() identifierType: string = '';
  @Input() hash: string;
  @Input() tokenMinutesExpiration = 60000;

  @Output() showContactModal = new EventEmitter();
  @Output() returnPage = new EventEmitter();

  showModal: boolean = true;
  secondStepForm: FormGroup;
  responseError: string;
  load: string = '';
  isExpired: boolean = false;
  helpDeskUri: string = environment.HELPDESK_URL;

  loadPage = new BehaviorSubject('waiting');
  _loadPage = this.loadPage.asObservable();

  constructor(
    private fb: FormBuilder,
    private transactionValidationService: TransactionValidationService,
    private deviceService: DeviceDetectorService,
    private swalErrorService: SwalErrorService,
    private router: Router,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.secondStepForm = this.fb.group({
      code: [null, [Validators.required, Validators.pattern("^[0-9]*$")]],
    });
  }

  codeOnSubmit() {
    this.load = 'show';

    const code = this.secondStepForm.get('code').value;

    this.transactionValidationService.putConfirm(this.hash, code)
    .subscribe(
      (res) => {
        this.load = '';

        this.challengeFinish();
      },
      (err) => {
        this.load = '';

        if(err.error.errors[0].code == "032")
          this.swalErrorService.show(err).then(() => { this.challengeFinish(); });
        else
          this.responseError = err.error.errors[0].message;
      }
    );
  }

  goToLogin() {
    this.router.navigate([``]);
  }

  getReturnUrlCallback(): string{
    let returnRoute = localStorage.getItem('urlAfterCallback');
    return returnRoute != null && returnRoute != undefined ? returnRoute : '';
  }

  injectHttps(url: string) {
    if (url.indexOf('https') === -1) {
      return url.replace(/^http:\/\//i, 'https://');
    }
    return url;
  }

  getReturnRoute(): string {
    let returnRoute = localStorage.getItem('rota');
    localStorage.removeItem('rota');
    return returnRoute !== null && returnRoute !== undefined ? returnRoute : '';
  }

  challengeFinish() {
    this.load = 'show';

    const channel = (this.deviceService.isDesktop() ? DeviceChannel.DesktopBrowser : DeviceChannel.MobileBrowser).toString();

    this.transactionValidationService.getChallengeFinish(this.hash, channel)
    .subscribe(
      (res) => {
        const accessToken = res.data.access_token;
        const skip = false;
        const returnUrlAfterCallback = this.getReturnUrlCallback();
        let returnurl = this.getReturnRoute();

        Cookies.set('dotzSessionToken', accessToken);
        localStorage.setItem('sessionToken', accessToken);

        if (returnUrlAfterCallback && returnUrlAfterCallback !== '')
          window.location.href = returnUrlAfterCallback + "?ffac79874009f401f68532cf47633=" + accessToken;
        else {
          let returnUrlHttps = this.injectHttps(returnurl);
          window.location.href = environment.SITE_DOTZ + "/DynamicData.aspx?originurl=" + returnUrlHttps + "&ffac79874009f401f68532cf47633=" + accessToken + "&skip=" + skip;
        }
      },
      (err) => {
        this.load = '';
        this.goToLogin();
      }
    );
  }

  timeExpired() {
    this.isExpired = true;
  }

  resend() {
    if (!this.isExpired) {
      this.alertService.danger('Aguarde o tempo informado para solicitação de um novo código de validação.');
      return;
    };

    this.load = 'show';

    this.transactionValidationService.putResendCode(this.hash)
      .subscribe(
        (res) => {
          this.load = '';
          this.isExpired = false;
          this.responseError = null;

          setTimeout(() => {
            this.countdownTimerComponent.startAgain();
          }, 1000);

          this.alertService.success(`Reenviamos o código de validação para o seu ${this.identifierType == 'sms' ? 'número de telefone' : 'email'}!`);
        },
        (err) => {
          this.load = '';
          this.goToLogin();
        }
      );
  }

  teste() {
    console.log("Kelven");
  }
}
