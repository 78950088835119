<dharma-ui-alert></dharma-ui-alert>

<div *ngIf="!isExpired">

  <p *ngIf="identifierType == 'sms'">
    Um <strong>código de validação</strong> foi enviado para <br>
    o número de telefone que você informou. <br>
    Insira abaixo.
  </p>

  <p *ngIf="identifierType == 'email'">
    Um <strong>código de validação</strong> foi enviado para <br>
    o e-mail que você informou. <br>
    Insira abaixo.
  </p>

  <div class="form">
    <form [formGroup]="secondStepForm" (ngSubmit)="codeOnSubmit()" autocomplete="off">
      <div class="input-wrapper">
        <input type="text" name="code" id="code" maxlength="6" minlength="6" formControlName="code">
      </div>

      <div class="input-wrapper">

        <div class="btn-flex-row">

          <app-btn type="button" text="Voltar" color="gray" size="default" (click)="returnPage.emit()"></app-btn>
  
          <app-btn type="submit" text="Confirmar" color="orange" size="default"
          [disabled]="!secondStepForm.get('code').valid"></app-btn>        

        </div>


        <div class="text-center">
          <strong class="error-msg" *ngIf="responseError != undefined && responseError != ''">{{ responseError }}</strong>
        </div>

        <div class="timer" [hidden]="isExpired">
          <dharma-countdown-timer #countdownTimer
            [timeLeft]="tokenMinutesExpiration"
            (onFinish)="timeExpired()">
          </dharma-countdown-timer>
        </div>

        <div class="options text-center">
          <p class="item resend" (click)="resend()">Não recebi. Reenviar</p>
          <p class="item central"><a class="central" [href]="helpDeskUri"> Central de Atendimento</a></p>
        </div>

        <div class="pass-instructions" *ngIf="identifierType === 'email'">
          <strong>Dica:</strong> <br>
          <span>Caso não receba o código de validação, verifique a caixa de spam do seu e-mail.</span>
        </div>

      </div>
    </form>
  </div>

</div>

<div *ngIf="isExpired">

  <p class="main-text">
    Tempo Expirado<br>
  </p>

  <div class="buttons" (click)="resend()">
    <app-btn type="button" text="Reenviar Código" color="orange" size="default"></app-btn>
  </div>
</div>

<app-load [option]="load"></app-load>
