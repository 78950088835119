import { Component, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TrackerService } from 'dharma-ui-analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'dashboard';

  constructor(private trackerService: TrackerService) {
    // se for http redirecionar para https
    const isProduction = environment.production;
    if (isProduction && (location.protocol !== 'https:')) {
      location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
    }

    // iniciando o serviço
    this.trackerService.start();
  }
}
