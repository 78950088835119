<!--
    == Contato com a central
  -->
<div class="contact-central" *ngIf="modalDependencies.contactCentral">
  <span class="close-modal" (click)="redirectToLogin()">X</span>

  <strong *ngIf="!responseError">Não reconhece o e-mail e telefone cadastrados?</strong>
  <strong *ngIf="responseError">{{ responseError }}</strong>

  <p>
    Entre em contato com a Central de Atendimento <br>
    para atualizar esses dados.
  </p>

  <p>Telefones</p>

  <p>
    Principais capitais e região metropolitana: <br>
    <strong>ligue para <a href="tel:+5540071339">4007 1339</a></strong>
  </p>

  <p>
    Demais localidades: <br>
    <strong>ligue para <a href="tel:+5508006011339">0800 601 1339</a></strong>
  </p>

  <p>
    Horário de atendimento: <br>
    de segunda a domingo, das 8h às 22h.
  </p>
</div>


<div class="contact-central" *ngIf="modalDependencies.unlockAccount">
  <span class="close-modal" (click)="redirectToLogin()">X</span>

  <strong *ngIf="!responseError">Não reconhece o e-mail e telefone cadastrados?</strong>
  <strong *ngIf="responseError">{{ responseError }}</strong>

  <p>
    Desbloqueie sua conta clicando no botão abaixo<br>
  </p>

  <div class="div-unlock-btn">
    <app-btn (click)="redirectUnlock()"
        type="submit" 
        text="Desbloquear" 
        color="orange" 
        size="default">
    </app-btn>
  </div>
</div>

<!--
    == Sem informações basicas
    Email e telefone
  -->
<div class="contact-central" *ngIf="modalDependencies.noBascicInformation">

  <p>
    Olá {{ userData?.attributes?.full_name ||  userData?.attributes?.email || userData?.attributes?.cellphone_number }}
  </p>

  <p>
    Bem vindo de volta!<br>
  </p>

  <p>
    Vimos que você não tem email nem telefone cadastrados<br>
  </p>

  <p>
    Para segurança da sua conta Dotz, é importante que estes dados sejam informados
  </p>

  <p>
    <strong>
      Por favor entre em contato com a central de atendimento <br>
    </strong>
  </p>

  <p>Telefones</p>

  <div class="text">
    Principais capitais e região metropolitana: <br>
    <strong>ligue para <a href="tel:+5540071339">4007 1339</a></strong>
  </div>

  <div class="text">
    Demais localidades: <br>
    <strong>ligue para <a href="tel:+5508006011339">0800 601 1339</a></strong>
  </div>

  <p>
    Horário de atendimento: <br>
    de segunda a domingo, das 8h às 22h.
  </p>
</div>