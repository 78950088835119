import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SignupService } from 'src/app/services/signup.service';
import { tap, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { forgetPasswordService } from 'src/app/forget-password/forget-password.service';
import { Helpers } from 'src/app/shared/helpers';
import { SwalErrorService } from 'src/app/shared/swal-errors.service';
import { DeviceMapperService } from '../../services/device-mapper.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  load: string = '';

  // Steps
  showFirst: boolean = true;
  showSecond: boolean = false;
  showThird: boolean = false;
  showAgreements: boolean = false;
  response: any = {};

  showConfirmSmsEmail: boolean = false;
  showSimpleModal: boolean = false;
  showChallengeModal: boolean = false;

  userLoginData: Object = {};
  params: any = {};
  queryParams: any = {};
  userCpf: string = '';
  hash: string = '';

  loginType: string = '';

  constructor(
    private service: SignupService,
    private router: Router,
    private route: ActivatedRoute,
    private forget: forgetPasswordService,
    private helper: Helpers,
    private swalErrorService: SwalErrorService,
    private deviceMapper: DeviceMapperService
  ) {
  }

  ngOnInit() {
    localStorage.removeItem('isTemporaryPassword');
    localStorage.removeItem('urlAfterCallback');

    this.params = this.route.snapshot.params;
    this.queryParams = this.route.snapshot.queryParams;

    if(this.queryParams.urlAfterCallback !== null && this.queryParams.urlAfterCallback !== undefined){
      localStorage.setItem('urlAfterCallback', this.queryParams.urlAfterCallback);
    }

    if (this.params && this.params.cpf) {
      this.firstStep(null, this.params.cpf);
    } else {
      this.showFirst = true;
    }
  }

  /**
   * @description se tiver o query param forceValidation = true o sistema irá redirecionar para a tela de confirmação
   */
  goToLogin() {
    this.service.loginData(this.userCpf || this.params.cpf).pipe(
      tap(response => this.response = response),
      switchMap(() => this.service.checkLogin(this.userCpf || this.params.cpf))
    )
      .subscribe((responseUser: any) => {

        console.log('responseUser', responseUser);
        console.log('response', this.response);

        const redirectToLogin = () => {
          this.userCpf = this.userCpf || this.params.cpf;
          this.showFirst = false;
          this.showConfirmSmsEmail = false;
          this.showSecond = true;
        };

        const redirectToConfirmIdentifier = () => {
          let userLoginData = responseUser;
          if (responseUser.groups.indexOf('unconfirmed_email') < 0 && responseUser.attributes.email != undefined) {
            userLoginData['new_cellphone'] = true;
          }

          if (responseUser.groups.indexOf('unconfirmed_cellphone') < 0 && responseUser.attributes.cellphone_number != undefined) {
            userLoginData['new_email'] = true;
          }
          this.userLoginData = userLoginData;
          this.showFirst = false;
          this.userCpf = this.userCpf || this.params.cpf;
          this.showSecond = false;
          this.showConfirmSmsEmail = true;
        };

        if (this.queryParams && this.queryParams.forceValidation && this.queryParams.forceValidation === 'true') {
          if (this.userLoginData && this.userLoginData['type'] && this.userLoginData['type'] === 5) {
            this.userLoginData = responseUser;
            redirectToLogin();
          }
          redirectToConfirmIdentifier();
        } else {
          this.userLoginData = responseUser;
          redirectToLogin();
        }

      }, (err) => {
        this.router.navigate(['']);
      });
  }

  getReturnUrlCallback(): string{
    let returnRoute = localStorage.getItem('urlAfterCallback');
    return returnRoute != null && returnRoute != undefined ? returnRoute : '';
  }

  firstStep(e: any, cpf?: string) {
    this.userCpf = e ? e.form.identifier : cpf;
    this.load = 'show';

    if (this.userCpf === 'cpf') {
      this.loginType = 'Nome';
    }
    if (this.userCpf === 'cnpj') {
      this.loginType = 'Razão Social';
    }

    const getWorkflowType = (identifier): number => {
      return identifier.length > 11 ? 2 : 1;
    };

    const data = {
      document: this.userCpf,
      workflow_type: getWorkflowType(e.form.identifier),
      input: e.agreements,
      version: 2
    };

    this.service.checkLogin(data).subscribe(response => {
      const shouldGoToLogin = response.data.workflowStepType === 'Finish';

      if (shouldGoToLogin) {
        // redirecionar para login
        if(e.agreements) {
          const parsedAgrements = JSON.parse(e.agreements)
          const dataTerms = {
            document: this.userCpf,
            onboardingOrigin: 2,
            agreements: parsedAgrements.agreements,
            platform: parsedAgrements.appVersion,
            modelPlatform: parsedAgrements.modelPlatform
          }
          this.service.agreeMultipleTerms(dataTerms).subscribe()
        }
        this.service.loginData(this.userCpf).pipe(
          tap(responseLogin => this.response = responseLogin),
        ).subscribe(() => {
          this.load = '';
          this.showFirst = false;
          this.showSecond = true;
        });
      } else {
        // redirecionar para signup
        const returnUrlAfterCallback = this.getReturnUrlCallback();

        if(returnUrlAfterCallback != '' && returnUrlAfterCallback != undefined){
          var url_signup = environment.ONBOARDING_SIGNUP + "/" + this.userCpf + "?urlAfterCallback=" + returnUrlAfterCallback;
          window.location.href = url_signup;
          return;
        }

        window.location.href = `${environment.ONBOARDING_SIGNUP}/${this.userCpf}`;
      }
    }, (err) => {
      this.swalErrorService.show(err).then(() => this.load = '');
    });
  }

  secondStep(e: any) {
    let returnurl = this.getReturnRoute();
    let skip: boolean;
    switch (e.valid) {
      case true:
        this.load = 'show';
        skip = false;

        const returnUrlAfterCallback = this.getReturnUrlCallback();
        if (returnUrlAfterCallback && returnUrlAfterCallback !== ''){
          const sessionId = '&dz-tp-ss=' + this.deviceMapper.get().session_id;
          window.location.href = returnUrlAfterCallback + "?ffac79874009f401f68532cf47633=" + e.token + sessionId;
          break;
        }

        let returnUrlHttps = this.injectHttps(returnurl);
        window.location.href = environment.SITE_DOTZ + "/DynamicData.aspx?originurl=" + returnUrlHttps + "&ffac79874009f401f68532cf47633=" + e.token + "&skip=" + skip;
        break;
      case false:

        if(e.challenge) {
          this.showChallengeModal = true;
          this.hash = e.hash;
        }

        break;
      default:
        break;
    }
  }

  thirdStep(e: any) {
    console.log(e);
  }

  agreements(e: any) {
    console.log(e);
  }

  getReturnRoute(): string {
    let returnRoute = localStorage.getItem('rota');
    localStorage.removeItem('rota');
    return returnRoute !== null && returnRoute !== undefined ? returnRoute : '';
  }

  injectHttps(url: string) {
    if (url.indexOf('https') === -1) {
      return url.replace(/^http:\/\//i, 'https://');
    }
    return url;
  }

  redirectUnlock() {
    this.router.navigate([`desbloquear-conta/${this.userCpf}`]);
  }

  redirectChallenge() {
    this.router.navigate([`challenge/${this.hash}`]);
  }

}
