<div [class]="'loading ' + option">
  
  <div class="wrapper">
    <!-- <img src="../assets/images/Icon-dzball.svg" alt="Dotz - Sua moeda para a vida render mais">
    <img src="../assets/images/Icon-dzball.svg" alt="Dotz - Sua moeda para a vida render mais">
    <img src="../assets/images/Icon-dzball.svg" alt="Dotz - Sua moeda para a vida render mais"> -->

    <div class="img">
      <img src="assets/images/ball-1.png" alt="Dotz - Sua moeda para a vida render mais">
    </div>
    <h2>Aguarde . . .</h2>
  </div>

</div>