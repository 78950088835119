import { FormGroup, AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

Injectable()
@Injectable()
export class Helpers {
   constructor() { }

   checkCPF(strCPF: string, form: FormGroup) {
      let Soma, Resto, borda_original;
      Soma = 0;

      if (
         (strCPF === '00000000000') ||
         (strCPF === '11111111111') ||
         (strCPF === '22222222222') ||
         (strCPF === '33333333333') ||
         (strCPF === '44444444444') ||
         (strCPF === '55555555555') ||
         (strCPF === '66666666666') ||
         (strCPF === '77777777777') ||
         (strCPF === '88888888888') ||
         (strCPF === '99999999999')) {
         return false;
      }

      for (let i = 1; i <= 9; i++) {
         Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      }

      Resto = (Soma * 10) % 11;
      if ((Resto == 10) || (Resto == 11)) {
         Resto = 0;
      }

      if (Resto != parseInt(strCPF.substring(9, 10))) {
         return false;
      }

      Soma = 0;
      for (let i = 1; i <= 10; i++) {
         Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      }

      Resto = (Soma * 10) % 11;
      if ((Resto === 10) || (Resto === 11)) {
         Resto = 0;
      }

      if (Resto !== parseInt(strCPF.substring(10, 11))) {
         return false;
      }
      return true;
   }

   isControlValid(control: AbstractControl) {
      if (control.status === 'VALID' || (control.status === 'DISABLED' && control.value !== null && control.value !== '')) {
         return true;
      } else {
         return false;
      }
   }

   sweetAlert(title, type, text, html?) {
      Swal.fire({
         title: title,
         text: text,
         icon: type,
         html: html,
         customClass: 'swal-wide',
         confirmButtonText: 'Continuar',
         confirmButtonColor: '#FFA101',
      });
   }

   checkCNPJ(cnpj: string, form: FormGroup) {

      cnpj = cnpj.replace(/[^\d]+/g, '');

      if (cnpj === '') {
         return false;
      }

      if (cnpj.length !== 14) {
         return false;
      }

      // LINHA 10 - Elimina CNPJs invalidos conhecidos
      if (cnpj === '00000000000000' ||
         cnpj === '11111111111111' ||
         cnpj === '22222222222222' ||
         cnpj === '33333333333333' ||
         cnpj === '44444444444444' ||
         cnpj === '55555555555555' ||
         cnpj === '66666666666666' ||
         cnpj === '77777777777777' ||
         cnpj === '88888888888888' ||
         cnpj === '99999999999999') {
            return false;
      }

      // Valida DVs LINHA 23 -
      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      const digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
         soma += +numeros.charAt(tamanho - i) * pos--;
         if (pos < 2) {
            pos = 9;
         }
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (+resultado !== +digitos.charAt(0)) {
         return false;
      }

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
         soma += +numeros.charAt(tamanho - i) * pos--;
         if (pos < 2) {
            pos = 9;
         }
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (+resultado !== +digitos.charAt(1)) {
         return false;
      }

      return true;

   }

   findAndRemove(array, find) {
      return array.filter(item => {
         if (item != find) {
            console.log('é diferente');
            return item;
         }
      });
   }

   showConfirmEmail(scope, enableEdit: boolean = true, isNew: boolean = true) {
      scope.modalType = 'email';
      scope.isNew = isNew;
      scope.enableEdit = enableEdit;
      scope.smsValid = true;
      scope.emailValid = false;
      scope.modalDependencies.contactCentral = false;
      scope.modalDependencies.noBascicInformation = false;
      scope.showSimpleModal = true;
      if (enableEdit) {
         scope.newEmailForm.get('new_email').enable();
      }
   }

   showConfirmPhone(scope, enableEdit: boolean = true, isNew: boolean = true) {
      scope.modalType = 'sms';
      scope.isNew = isNew;
      scope.enableEdit = enableEdit;
      scope.emailValid = true;
      scope.smsValid = false;
      scope.modalDependencies.contactCentral = false;
      scope.modalDependencies.noBascicInformation = false;
      scope.showSimpleModal = true;
      if (enableEdit) {
         scope.newCellphoneForm.get('cellphone_number').enable();
      }
   }

   showTokenPhone(scope) {
      scope.modalType = 'sms';
      scope.isNew = false;
      scope.enableEdit = false;
      scope.emailValid = false;
      scope.smsValid = false;
      scope.showModalSMSEmail = true;
      scope.modalDependencies.contactCentral = false;
      scope.modalDependencies.noBascicInformation = false;
      scope.showSimpleModal = false;
      scope.newCellphoneForm.get('cellphone_number').enable();
   }

   showTokenEmail(scope) {
      scope.modalType = 'email';
      scope.isNew = false;
      scope.enableEdit = false;
      scope.smsValid = false;
      scope.emailValid = false;
      scope.showSimpleModal = false;
      scope.modalDependencies.contactCentral = false;
      scope.modalDependencies.noBascicInformation = false;
      scope.showModalSMSEmail = true;
      scope.newEmailForm.get('new_email').enable();
   }

   showContactModal(scope) {
      scope.modalDependencies.contactCentral = true;
      scope.modalDependencies.noBascicInformation = false;
   }

   showNoBascicInformationModal(scope) {
      scope.responseError = 'Você não possui email e telefone registrado.';
      scope.openInfoModal('central-force');
   }

   endValidation(scope) {
      scope.smsValid = false;
      scope.showSimpleModal = true;
      scope.showModalSMSEmail = false;
      scope.emailValid = false;
      scope.load = '';
      scope.validateSuccess = true;
   }

   isInfoModalOpened(scope) {
      let result: boolean = false;
      Object.keys(scope.modalDependencies).forEach(key => {
         const value = scope.modalDependencies[key];
         if (value) {
            result = true;
         }
      });
      return result;
   }

   getTimeLeft(timeout) {
      return Math.ceil((timeout._idleStart + timeout._idleTimeout - Date.now()) / 1000);
   }
}