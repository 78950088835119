<app-step-box>
  <div [@loadPage]="loadPage.getValue()">
    <h1>Verificamos que você esta utilizando uma senha temporária</h1>

    <p>Escolha sua nova senha</p>

    <div class="form">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
        <div class="input-wrapper">
          <input type="number" pattern="[0-9]*" inputmode="numeric" placeholder="Senha temporária" formControlName="old_password" maxlength="6">
        </div>

        <div class="input-wrapper">
          <input type="number" pattern="[0-9]*" inputmode="numeric" placeholder="Nova senha" formControlName="new_password" maxlength="6">
        </div>

        <div class="input-wrapper">
          <input type="number" pattern="[0-9]*" inputmode="numeric" placeholder="Confirmar nova senha" formControlName="confirm_newPassword" maxlength="6">
        </div>

        <div class="pass-instructions">
          <strong>Como montar minha senha?</strong> <br>
          <span>A senha deve ser composta por 6 números.</span>
        </div>

        <small class="msg-error password"
          *ngIf="(form.get('new_password').touched && form.get('new_password').value !== form.get('confirm_newPassword').value) && (form.get('new_password').value && form.get('confirm_newPassword').value)">
          As senhas não correspondem</small>
        <small class="msg-error password"
          *ngIf="form.get('new_password').hasError('isNumber') && form.get('new_password').touched">Apenas números são
          permitidos</small>

        <div class="cta-wrapper">
          <app-btn color="orange" type="submit" text="Alterar Senha" size="default" [disabled]="!form.valid">
          </app-btn>
        </div>
      </form>
    </div>
  </div>
</app-step-box>

<app-modal-token [enableSkip]="false" [cpf]="params && params.cpf ? params.cpf : null" [type]="dependencies.modalType" [showModal]="dependencies.showTokenModal"
  [optionsAPI]="dependencies.optionsAPI" (response)="onTokenSubmit($event)" (sendTokenAgain)="sendTokenAgain($event)"
  (clearTransactionID)="dependencies.optionsAPI.transaction_id = null;">
  <p *ngIf="dependencies.modalType == 'sms'">
    Um código de validação foi enviado <br>
    para o número de telefone que você informou. <br>
    Insira abaixo.
  </p>

  <p *ngIf="dependencies.modalType == 'email'">
    Um <strong>código de validação</strong> foi enviado para <br>
    o e-mail cadastrado e ficará disponível por 24h. <br>
    Digite-o abaixo:
  </p>
</app-modal-token>

<app-simple-modal [showModal]="showSimpleModelReenvio">
  <form>
    <div class="cellphone-success">
      <div class="input-wrapper">
        <p>
          Um código de validação foi enviado <br>
          para <ng-container *ngIf="dependencies.modalType == 'sms'">o número de telefone</ng-container>
          <ng-container *ngIf="dependencies.modalType == 'email'">o e-mail</ng-container> que você informou.
        </p>
      </div>
      <br>
      <div class="cta-wrapper input-wrapper">
        <p>
          <app-btn color="orange" type="submit" text="Continuar" size="default"
            (click)="showSimpleModelReenvio = false;">
          </app-btn>
        </p>
      </div>
    </div>
  </form>
</app-simple-modal>

<app-simple-modal [showModal]="showModalSendMfa">
  <div [@loadPage]="loadPage.getValue()">
    <h1>Alterar a senha</h1>

    <p>Escolha um identificador para confirmar a alteração de senha</p>

    <div class="form">
      <form [formGroup]="formIdentifider" (ngSubmit)="onIdentifierSubmit()" autocomplete="off">
        <div class="input-wrapper">
          <div class="input-wrapper" *ngFor="let identifierItem of identifiers">
            <div class="dz-custom-radio">
              <input type="radio" name="identifier" id="{{ identifierItem?.option_id }}-radio"
                [value]="identifierItem?.option_id" formControlName="identifier">
              <label for="{{ identifierItem?.option_id }}-radio"></label>
            </div>
            <div class="text">
              <label for="phone">
                {{ identifierItem.option_id === 1 ? 'Email' : 'Telefone' }} <br>
                {{ identifierItem?.value }}
              </label>
            </div>
          </div>
        </div>

        <div class="text-center">
          <strong class="error-msg" *ngIf="responseError">*{{ responseError }}</strong>
        </div>

        <div class="cta-wrapper">
          <app-btn color="orange" type="submit" text="Confirmar" size="default"
            [disabled]="!formIdentifider.get('identifier').valid">
          </app-btn>
        </div>
      </form>
    </div>
  </div>
</app-simple-modal>

<app-modal-token [cpf]="params && params.cpf ? params.cpf : null"  [type]="dependencies.modalType" [showModal]="dependencies.showTokenModal"
  [optionsAPI]="dependencies.optionsAPI" (response)="onTokenSubmit($event)" (sendTokenAgain)="sendTokenAgain($event)"
  [transactionid]="dependencies.transaction_id">
  <p *ngIf="dependencies.modalType == 'sms'">
    Um código de validação foi enviado <br>
    para o número de telefone que você informou. <br>
    Insira abaixo.
  </p>

  <p *ngIf="dependencies.modalType == 'email'">
    Um <strong>código de validação</strong> foi enviado para <br>
    o e-mail cadastrado e ficará disponível por 24h. <br>
    Digite-o abaixo:
  </p>
</app-modal-token>

<app-simple-modal [showModal]="showSimpleModelReenvio">
  <form>
    <div class="cellphone-success">
      <div class="input-wrapper">
        <p>
          Um código de validação foi enviado <br>
          para <ng-container *ngIf="dependencies.modalType == 'sms'">o número de telefone</ng-container>
          <ng-container *ngIf="dependencies.modalType == 'email'">o e-mail</ng-container> que você informou.
        </p>
      </div>
      <br>
      <div class="cta-wrapper input-wrapper">
        <p>
          <app-btn color="orange" type="submit" text="Continuar" size="default"
            (click)="showSimpleModelReenvio = false;">
          </app-btn>
        </p>
      </div>
    </div>
  </form>
</app-simple-modal>

<app-load [option]="loading"></app-load>