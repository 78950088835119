import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-info-container',
  templateUrl: './modal-info-container.component.html',
  styleUrls: ['./modal-info-container.component.scss']
})
export class ModalInfoContainerComponent {

  @Output() onClose = new EventEmitter();

  @Input() modalDependencies: any = {};
  @Input('userData') userData: any;
  @Input() responseError: String;
  @Input() showUnlockBtn: boolean = false;

  constructor(
    private router: Router
  ) {}

  redirectToLogin() {
    const returnUrlAfterCallback = this.getReturnUrlAfterCallback();
    if (returnUrlAfterCallback !== '') {
      window.location.href = returnUrlAfterCallback;
    } else {
      window.location.href = '/';
    }
  }

  getReturnUrlAfterCallback(): string {
    let returnRoute = localStorage.getItem('urlAfterCallback');
    localStorage.removeItem('urlAfterCallback');
    return returnRoute !== null && returnRoute !== undefined ? returnRoute : '';
  }
  redirectUnlock() {
    this.router.navigate([`desbloquear-conta/${ this.userData.cpf }`]);
  }

}
