import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetLoginModel } from './models/getLogin.model';
import { CellConfirmModel } from './models/cellConfirm.model';
import { EmailConfirmModel } from './models/emailConfirm.model';
import { LoginDataModel } from './models/loginData.model';
import { AddressModel } from './models/address.model';
import { environment } from 'src/environments/environment';
import { AgreementsPendingModel } from './models/agreementsPending.model';
import { AgreementsAgreeModel } from './models/agreementsAgree.mode';
import { QuizModel } from './models/quiz.model';
import { PostCodeModel } from './models/postCode.model';

@Injectable({
  providedIn: 'root'
})

export class SignupService {

  constructor(private http: HttpClient) { }


  checkLogin(data: any = {}): Observable<GetLoginModel> {
    return this.http.post<GetLoginModel>(`${environment.SSO_DEFAULT_URI_SIGNUP}/v5/workflow/nextstep`, data,
      {
        headers: { Authorization: `Basic ${environment.SSO_CLIENT_ID}` }
      });
  }

  /**
  *
  * @param cpf
  * @description Informações do cliente para login.
  */
  loginData(cpf: string): Observable<LoginDataModel> {
    return this.http.get<LoginDataModel>(`${environment.SSO_DEFAULT_URI_ACCOUNTS}/accounts/${cpf}`);
  }

  /**
  *
  * @param loginData
  * @description Autenticação na accounts
  */
  login(loginData: FormData): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Basic ${environment.SSO_CLIENT_ID}`
      })
    };
    return this.http.post<any>(`${environment.SSO_DEFAULT_URI_ACCOUNTS}/connect/token`, loginData, httpOptions);
  }

  /**
  *
  * @param access_token
  * @description Gera sessão no site Dotz.
  */
  authenticate(access_token: string): Observable<any> {
    let body = new FormData();
    body.append('token', access_token);
    body.append('originUrl', location.origin);
    body.append('app', environment.app_id);
    body.append('redirect', environment.SITE_DOTZ);

    return this.http.post<any>(`${environment.SITE_DOTZ}/Authenticate.aspx`, body);
  }

  /**
  *
  * @param cep
  * @description Serviço de consulta CEP
  */
  getCep(cep: string): Observable<PostCodeModel> {
    return this.http.get<PostCodeModel>(`${environment.SSO_DEFAULT_URI_ADDRESS}/${cep}?api-version=2.0`);
  }

  /**
  *
  * @param transactionid
  * @param token
  * @description Verificação do token enviado via sms e email.
  */
  confirmCellEmailToken(transactionid: string, token: string): Observable<any> {
    let data = {
      transaction_id: transactionid,
      code: token,
    }
    return this.http.put<any>(`${environment.SSO_DEFAULT_URI_ACCOUNTS}/v2/mfa/transactions/`, data);
  }

  /**
  *
  * @param document
  * @param options
  * @description Verificação de email e telefone existente.
  */
  confirmExistEmailCellphone(document: string, options: Object): Observable<Object> {
    return this.http.put<Object>(`${environment.SSO_DEFAULT_URI_ACCOUNTS}/v2/mfa/${document}`, options);
  }

  /**
   *
   * @param document
   * @param options
   */
  resetMFA(document: string): Observable<Object> {
    return this.http.delete<Object>(`${environment.SSO_DEFAULT_URI_ACCOUNTS}/v2/mfa/${document}`);
  }

  /**
  *
  * @param confirmCellData
  * @description Envia o token via SMS
  */
  confirmCell(confirmCellData: CellConfirmModel): Observable<CellConfirmModel> {
    return this.http.post<CellConfirmModel>(`${environment.SSO_DEFAULT_URI_SIGNUP}/v2/steps/step1/`, confirmCellData);
  }

  /**
  *
  * @param confirmEmailData
  * @description Envia o token por email.
  */
  confirmEmail(confirmEmailData: EmailConfirmModel): Observable<EmailConfirmModel> {
    return this.http.post<EmailConfirmModel>(`${environment.SSO_DEFAULT_URI_SIGNUP}/v2/steps/step2/`, confirmEmailData);
  }

  /**
  *
  * @param address
  * @description Etapa para gravar endereço de novos clientes.
  */
  saveAddress(address: AddressModel): Observable<AddressModel> {
    return this.http.post<AddressModel>(`${environment.SSO_DEFAULT_URI_SIGNUP}/v2/steps/step3/`, address)
  }

  /**
  *
  * @param questions
  * @description Etapa para gravar as perguntas secretas de novos clientes
  */
  saveQuiz(questions: QuizModel): Observable<QuizModel> {
    return this.http.post<QuizModel>(`${environment.SSO_DEFAULT_URI_SIGNUP}/v2/steps/step4`, questions);
  }

  /**
  *
  * @param questions
  * @description Etapa para gravar as perguntas secretas que estejam pendentes
  */
  saveQuizAccounts(questions: QuizModel): Observable<QuizModel> {
    return this.http.post<QuizModel>(`${environment.SSO_DEFAULT_URI_SIGNUP}/v2/steps/step4`, questions);
  }

  /**
  *
  * @param password
  * @description Etapa para salvar senha dos novos clientes.
  */
  savePassword(password: Object): Observable<Object> {
    return this.http.post<Object>(`${environment.SSO_DEFAULT_URI_SIGNUP}/v2/steps/step5`, password);
  }

  /**
  *
  * @param { string } code
  * @description Consulta do contato vigente
  */
  getContract(termCode: string = '08'): Observable<AgreementsPendingModel> {
    return this.http.get<AgreementsPendingModel>(`${environment.SSO_DEFAULT_URI_AGREEMENTS}/contract/${termCode}`);
  }

  /**
   *
   * @param code
   * @returns Se um usuario fez os aceites dos termos
   */
  verifyContract(code: string, document: string): Promise<any> {
    return this.http.post(`${environment.SSO_DEFAULT_URI_SIGNUP}/v5/agreements/option/GetOptInStatusFromQuery?ContractTermCode=${code}&UserIdentifier=${document}`, null, {
      headers: {
        Authorization: `Basic ${environment.SSO_CLIENT_ID}`,
        rfc_http_v11: 'true'
      }
    })
      .toPromise();
  }

    /**
   *
   * @param code
   * @returns Se um usuario fez os aceites dos termos
   */
  verifyContractArray(codes: string[], document: string): Promise<any> {
    var body = {
      contractTermCode: codes,
      userIdentifier: document
    };

    return this.http.post(`${environment.SSO_DEFAULT_URI_SIGNUP}/v5/agreements/option/GetOptInStatusFromArray`, body, {
      headers: {
        Authorization: `Basic ${environment.SSO_CLIENT_ID}`,
        rfc_http_v11: 'true'
      }
    })
    .toPromise();
  }


  /**
  *
  * @param { string } code
  * @description Consulta do contato vigente na API Agreements por usuario
  */
  // getContractUserAgreements(code: string, document: string): Observable<any> {
  //   return this.http.get<any>(`${environment.API_AGREEMENTS_PRIVATE}/optin/getoptinstatusfromquery?ContractTermCode=${code}&UserIdentifier=${document}`);
  // }

  getContractUserAgreements(code: string, document: string): Promise<any> {
    return this.http.get<any>(`${environment.API_AGREEMENTS_PRIVATE}/optin/getoptinstatusfromquery?ContractTermCode=${code}&UserIdentifier=${document}`)
      .toPromise();
  }

  agreeMultipleTerms(data: object): Observable<any> {
    return this.http.post(`${environment.SSO_DEFAULT_URI_SIGNUP}/v5/agreements/multipleterms`, data, {
      headers: { Authorization: `Basic ${environment.SSO_CLIENT_ID}` }
    })
  }

  /**
  *
  * @param data
  * @description Etapa de gravação do aceite do termo.
  */
  agreeContract(data: AgreementsAgreeModel): Observable<AgreementsAgreeModel> {
    return this.http.post<AgreementsAgreeModel>(`${environment.SSO_DEFAULT_URI_AGREEMENTS}/agree/postagree`, data);
  }

  sendTransaction(transactionid: string): Observable<any> {
    return this.http.put<any>(`${environment.SSO_DEFAULT_URI_ACCOUNTS}/v2/mfa/${transactionid}/send`, {});
  }

  verifyUserLoyalt(document: string): Observable<any> {
    return this.http.get(`${environment.SSO_DEFAULT_URI_ACCOUNTS}accounts/${document}`)
  }

}
