
import { trigger, state, style } from '@angular/animations';

export const LoadPageAnimation = trigger('loadPage', [
   state('waiting', style({
     opacity: 0,
     transform: 'translateY(50px)'
   })),
   state('loaded', style({
     opacity: 1,
     transform: 'translateY(0px)',
     transition: 'all 500ms ease-in-out'
   }))
 ]);

 export const ShowHideAnimation = trigger('showHide', [
   state('close', style({
     height: 0,
     overflow: 'hidden',
     opacity: 0,
     transform: 'translateY(100px)',
     margin: 0
   })),
   state('open', style({
     height: 'auto',
     overflow: 'auto',
     opacity: 1,
     transform: 'translateY(0px)',
     transition: 'all 500ms ease-in-out'
   }))
 ]);