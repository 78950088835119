<div class="userName" [@loadPage]="loadPage.getValue()">
  
  <p>Este é o seu nome?</p>
  <strong>{{ userFullName }}</strong>
  <span>Se não for, <a href="javascript:void(0)" class="text-link" (click)="refreshPage()">faça o login com outra conta</a></span>

</div>

<div class="password" [@loadPage]="loadPage.getValue()">
  <div class="form">
    <form [formGroup]="secondForm" (ngSubmit)="onSubmit()" autocomplete="off">

      <div class="msg-error-wrapper" *ngIf="msgError !== ''">
        <small class="msg-error"> **{{ msgError }}** </small> 
        <p class="item central"><a class="central" *ngIf="showhelpDeskUri" [href]="helpDeskUri"> Central de Atendimento</a></p>
      </div>

      <p class="text-password">Digite sua senha utilizando o teclado virtual da <strong class="bold">Dotz</strong></p>

      <div class="input-wrapper password">
        <input type="password" formControlName="password" maxlength="6">
      </div>

      <ul class="passList">
        <li *ngFor="let item of virtualKeyboard; let i = index" (click)="setPass( i )"> {{ item }} </li>
        <li (click)="clearPassword()">Limpar</li>
      </ul>

      <a *ngIf="showEsqueciMinhaSenha" href="javascript:void(0)" (click)="forgetPassword()" class="reset-pass">Esqueci minha senha</a>

      <div class="input-wrapper birthday">
        <label>Confirme seu <strong class="bold">{{ dateValidation }}</strong> de nascimento:</label>
        <input #birthday type="text" maxlength="2" formControlName="birthday">
      </div>

      <div class="recaptcha-wrapper" *ngIf="userData['isRecaptchaActive']">
        <re-captcha (resolved)="resolveCaptcha($event)" siteKey="6Lf66pkUAAAAALy7lqv7bqAxCYKK0TbQ3CYhvYMz"></re-captcha>
      </div>

      <app-btn type="submit" text="Entrar" color="orange-light" size="btn-retangle" [disabled]="secondForm.invalid"></app-btn>

    </form>
  </div>
</div>

<app-load [option]="load"></app-load>

<app-simple-modal [showModal]="showSimpleModal">
  <app-modal-info-container 
    [userData]="userData"
    [modalDependencies]="modalDependencies" 
    [responseError]="modalDependencies.responseError">
  </app-modal-info-container>
</app-simple-modal>