<app-step-box>
  <app-first-step (response)="firstStep($event)" *ngIf="showFirst"></app-first-step>
  <app-second-step [userData]="response" [cpf]="userCpf" (response)="secondStep($event)" *ngIf="response && showSecond"></app-second-step>
</app-step-box>

<app-load [option]="load"></app-load>

<app-simple-modal [showModal]="showChallengeModal">
  <div class="contact-central">
    <strong>Confirme seu dispositivo</strong>
    
    <p>
      Clique no botão abaixo para realizar a validação do seu login e entrar na sua conta Dotz<br>
    </p>

    <app-btn (click)="redirectChallenge()"
      type="submit" 
      text="Confirmar" 
      color="orange" 
      size="default">
    </app-btn>
  </div>
</app-simple-modal>

<app-simple-modal [showModal]="showSimpleModal">
  <div class="contact-central">
    <strong>Conta bloqueada!</strong>
    
    <p>
      Desbloqueie sua conta clicando no botão abaixo<br>
    </p>

    <app-btn (click)="redirectUnlock()"
      type="submit" 
      text="Desbloquear" 
      color="orange" 
      size="default">
    </app-btn>
  </div>
</app-simple-modal>