<div class="step-box-wrapper">

  <div class="step-box__header">
    <img src="assets/images/logo_transparente.svg" alt="Dotz - Sua moeda para a vida render mais">
  </div>

  <div class="step-box__content">
    <ng-content></ng-content>
  </div>

</div>