<!-- Modal envio de SMS -->
<div class="modal-sms" [ngClass]="{'show': showModal, 'close' : !showModal}">
  <div class="modal-sms-wrapper" [ngClass]="{'show': showModal, 'close' : !showModal}">
    <ng-content></ng-content>

    <div class="form">
      <form [formGroup]="smsForm" (ngSubmit)="smsOnSubmit()" autocomplete="off">
        <div class="input-wrapper">
          <input type="text" name="num1" id="num1" maxlength="6" minlength="6" formControlName="num1">
        </div>

        <div class="input-wrapper">
          <app-btn type="submit" text="Confirmar" color="orange" size="default"
            [disabled]="smsForm.status === 'DISABLED'"></app-btn>

          <div class="text-center">
            <strong class="error-msg" *ngIf="responseError">*{{ responseError }}</strong>
          </div>

          <app-countdown-timer *ngIf="!canSendTokenAgain && showModal"
            (onFinish)="canSendTokenAgain = true">
          </app-countdown-timer>

          <a [class.disabled]="!canSendTokenAgain" href="javascript:void(0)" [class]="canSendTokenAgain ? 'send-again' : 'send-again-disabled'"
            (click)="fnSendTokenAgain( type )">Não recebi. Reenviar</a>
            <a href="javascript:void(0)" class="show-contact" (click)="showContactModal.emit(true)">Central de
              Atendimento</a>
          <a *ngIf="skipEnabled && enableSkip" href="javascript:void(0)" class="show-contact" (click)="skip()" style="font-size: 24px; margin-top: 20px">Pular</a>

          <div class="pass-instructions" *ngIf="type === 'email'">
            <strong>Dica:</strong> <br>
            <span>Caso não receba o código de validação, verifique a caixa de spam do seu e-mail.</span>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>

<app-load [option]="load"></app-load>