import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SignupService } from 'src/app/services/signup.service';
import { Helpers } from '../helpers';
import { CountdownTimerComponent } from 'src/app/components/countdown-timer/countdown-timer.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

declare var require: any

@Component({
  selector: 'app-modal-token',
  templateUrl: './modal-token.component.html',
  styleUrls: ['./modal-token.component.scss']
})
export class ModalTokenComponent implements OnInit {

  @ViewChild(CountdownTimerComponent) CountdownTimerComponent: CountdownTimerComponent;

  smsForm: FormGroup;
  transactionId: string;
  attemptsSendTokenAgain: number = 0;
  responseError: string;

  @Input() type: string = ''; //sms or email
  @Input() showModal: boolean = false;
  @Input() optionsAPI: any = {};
  @Input() transactionid: string = '';
  @Input() cpf: string;
  @Input() enableSkip: boolean = true;
  @Output() response = new EventEmitter();
  @Output() sendTokenAgain = new EventEmitter();
  @Output() showContactModal = new EventEmitter();
  @Output() clearTransactionID = new EventEmitter();

  load: string = '';
  canSendTokenAgain: boolean = false;
  canSendTokenAgainTimer;

  skipEnabled: boolean;

  constructor(
    private service: SignupService,
    private helper: Helpers,
    private router: Router,
  ) { }

  ngOnInit() {
    this.skipEnabled = environment.skipEnabled;
    this.smsForm = new FormGroup({
      num1: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
    });
  }

  smsOnSubmit() {
    this.load = 'show';

    this.service.confirmCellEmailToken(this.transactionid, this.smsForm.get('num1').value).subscribe(response => {
      this.load = '';
      this.smsForm.reset();
      response.transaction_id = response && response.transaction_id ? response.transaction_id : null;
      const transaction_id = this.transactionId ? this.transactionId : response.transaction_id;
      this.response.emit({ valid: true, type: this.type, transaction_id: transaction_id ? transaction_id : null });
    }, err => {
      console.log('err', err);
      this.load = '';
      this.smsForm.reset();
      switch (err.error.code) {
        case '274':
        case '275':
          this.smsForm.setErrors({ 'invalid': true });
          this.showContactModal.emit(true);
          this.response.emit({ valid: false, type: this.type, msg: 'Confirmação bloqueada, entre em contato com a central.' });
          break;
        case '270':
          this.smsForm.setErrors({ 'invalid': true });
          this.showContactModal.emit(true);
          this.response.emit({ valid: false, type: this.type, msg: 'Ocorreu um erro, entre em contato com a central.' });
          break;
        case '165':
          this.smsForm.setErrors({ 'invalid': true });
          this.responseError = 'Token Inválido, tente novamente.';
          this.response.emit({ valid: false, type: this.type, msg: 'Token Inválido, tente novamente.' });
          break;
        default:
          this.smsForm.setErrors({ 'invalid': true });
          this.response.emit({ valid: false, type: this.type, msg: 'Token Inválido, tente novamente.' });
          break;
      }
    });

  }

  nextCode(e: Event) {
    let target = e.target as any;
    target.nextElementSibling.focus();
  }

  fnSendTokenAgain(type: string) {
    if (!this.canSendTokenAgain) {
      this.responseError = '';
      return;
    }

    this.attemptsSendTokenAgain = this.attemptsSendTokenAgain + 1;
    if (this.attemptsSendTokenAgain > 3) {
      this.canSendTokenAgain = false;
      this.smsForm.disable();
      this.response.emit({ valid: false, type: this.type, msg: 'Você atingiu o limite máximo de requisição de token.' });
      return;
    }
    this.sendTokenAgain.emit({
      type,
      options: this.optionsAPI,
    });
    this.canSendTokenAgain = false;
    this.resetCountdown();
  }

  resetCountdown() {
    this.CountdownTimerComponent.startAgain();
  }

  skip() {
    this.service.resetMFA(this.cpf).subscribe(() => {
      this.router.navigate([`login/${this.cpf}`]);
    }, (err) => {
      const redirectLogin = () => {
        window.location.reload();
      };  
      switch (err.error.code) {
        case '284':
          redirectLogin();
          break;
        default:
          redirectLogin();
          break;
      }
    });
  }

}
