import { Component, OnInit, Output, EventEmitter, AfterContentInit, HostListener, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { LoadPageAnimation } from 'src/app/shared/animations';
import { BehaviorSubject } from 'rxjs';

import { TransactionValidationService } from 'src/app/services/transaction-validation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-first-step-challenge',
  templateUrl: './first-step.component.html',
  styleUrls: ['./first-step.component.scss'],
  animations: [
    LoadPageAnimation
  ]
})
export class FirstStepChallengeComponent implements OnInit, AfterContentInit {

  @Input() hash: string;
  @Output() response = new EventEmitter();
  
  firstStepForm: FormGroup;
  identifiers: any;
  load: string = '';

  loadPage = new BehaviorSubject('waiting');
  _loadPage = this.loadPage.asObservable();

  constructor(
    private transactionValidationService: TransactionValidationService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit() {
    if(this.hash !== undefined)
      this.getIdentifiersConfirm();

    this.firstStepForm = this.fb.group({
      identifier: [null, Validators.required],
    });
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.loadPage.next('loaded');
    }, 200);
  }

  getIdentifiersConfirm(): void {
    this.load = 'show';
    this.transactionValidationService.getIdentifiersConfirm(this.hash)
    .subscribe(
      (res) => {
        this.load = '';

        if(res.data.length == 0)
          this.goToLogin();

        this.identifiers = res.data;
      },
      (err) => {
        this.load = '';

        this.goToLogin();
      }
    );  
  }

  goToLogin() {
    this.router.navigate([``]);
  }

  onIdentifierSubmit() {
    this.load = 'show';

    const optionId = this.firstStepForm.get('identifier').value;

    const identifierType = optionId == 1 ? "email" : "sms";

    this.transactionValidationService.putSendCode(this.hash, optionId)
    .subscribe(
      (res) => {
        this.load = '';

        let minutesExpiration = res.data;
        let millisecExpiration = minutesExpiration * 60000;
        this.response.emit({valid: true, identifierType, tokenMinutesExpiration: millisecExpiration});
      },
      (err) => {
        this.load = '';

        this.goToLogin();
      }
    ); 
  }
}
