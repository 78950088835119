<app-step-box>
  <div [@loadPage]="loadPage.getValue()">
    <h1>Digite a nova senha</h1>

    <div class="form" style="padding-top: 20px">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">

        <div class="input-wrapper">
          <input type="number" pattern="[0-9]*" inputmode="numeric" placeholder="Nova senha" formControlName="new_password" maxlength="6">
        </div>

        <div class="input-wrapper">
          <input type="number" pattern="[0-9]*" inputmode="numeric" placeholder="Confirmar nova senha" formControlName="confirm_newPassword" maxlength="6">
        </div>

        <div class="pass-instructions">
          <strong>Como montar minha senha?</strong> <br>
          <span>A senha deve ser composta por 6 números.</span>
        </div>

        <small class="msg-error password"
          *ngIf="(form.get('new_password').touched && form.get('new_password').value !== form.get('confirm_newPassword').value) && (form.get('new_password').value && form.get('confirm_newPassword').value)">
          As senhas não correspondem</small>
        <small class="msg-error password"
          *ngIf="form.get('new_password').hasError('isNumber') && form.get('new_password').touched">Apenas números são
          permitidos</small>

        <div class="cta-wrapper">
          <app-btn color="orange" type="submit" text="Criar Senha" size="default" [disabled]="!form.valid">
          </app-btn>
        </div>
      </form>
    </div>
  </div>
</app-step-box>