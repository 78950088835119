import { dynamicEnv } from 'dharma-ui-common';

export const environment = {
  production: true,
  SSO_CLIENT_ID: 'V0VSU0ZGNzg5NDU2MjQ1NzM6NDU2U0RGU0Q3ODUyNTgxNTQ=',
  SSO_DEFAULT_URI_ACCOUNTS: 'https://api.dotz.com.br/accounts/api/default',
  SSO_DEFAULT_URI_SIGNUP: 'https://api.dotz.com.br/signup/api/default',
  ONBOARDING_SIGNUP: 'https://api.dotz.com.br/signup/ui/default/steps',
  SIGNUP_UI_URI: 'https://api.dotz.com.br/signup/ui/default/',
  SSO_DEFAULT_URI_AGREEMENTS: 'https://api.dotz.com.br/agreements/api/default/v1',
  DEVICE_MAPPER_CLIENT: 'dotz',
  DEVICE_MAPPER_URL: 'https://dotz.dnofd.com/ofdb/OFDB.js',
  TERM_CODE_USE_1: '326',
  TERM_CODE_USE_2: '07',
  TERM_CODE_POLICTS_1: '329',
  TERM_CODE_POLICTS_2: '328',
  TERM_CODE_POLICTS_3: '327',
  API_AGREEMENTS_PRIVATE: 'https://api.dotz.com.br/agreements/api/private/v1',
  API_TRANSACTIONVALIDATION_DEFAULT: 'https://api.dotz.com.br/transaction-validation/api/default/v1',
  SSO_DEFAULT_URI_ADDRESS: 'https://api.dotz.com.br/address-data/api/default/v2/addresses',
  SITE_DOTZ: 'https://troque.dotz.com.br',
  SSO_CLIENTID_SCOPES: 'accounts.api members.api offers.api multicampaign.api clubedotz.api redemptions.api wallet.api webplatform.api provisioncredit.api rewards.api platformcatalog.api transactionvalidation.api',
  UI_VERSION: '1',
  app_id: 'Y5U4LPXHXAKGIC7',
  skipEnabled: true,
  HELPDESK_URL: 'https://dotz.com.br/dotzatendimento',
  NEW_LOGIN: 'https://auth.dotz.com.br',
  ...dynamicEnv,
};
