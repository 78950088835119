import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-btn',
  templateUrl: './dzbutton.component.html',
  styleUrls: ['./dzbutton.component.scss']
})
export class DzbuttonComponent implements OnInit {

  @Input() text: string = '';
  @Input() color: string = '';
  @Input() size: string = '';
  @Input() type: string = '';
  @Input() disabled: boolean = false;
  @Output() click: any = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
