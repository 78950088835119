import { Component, OnInit } from '@angular/core';
import { LoadPageAnimation } from '../shared/animations';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { forgetPasswordService } from '../forget-password/forget-password.service';
import { Helpers } from '../shared/helpers';
import { ActivatedRoute } from '@angular/router';
import { SignupService } from '../services/signup.service';
import { ValidatorsUtil } from '../shared/validators.util';

@Component({
  selector: 'app-unlock-generate-password',
  templateUrl: './unlock-generate-password.component.html',
  styleUrls: ['./unlock-generate-password.component.scss'],
  animations: [
    LoadPageAnimation
  ],
})
export class UnlockGeneratePasswordComponent implements OnInit {

  form: FormGroup;
  transactionID: string;
  showModalSuccess = false;
  showTokenModal = false;
  showModalSendMfa = false;
  showModalSMSEmail = false;
  showSimpleModelReenvio = false;
  loading = '';
  responseError;

  loadPage = new BehaviorSubject('waiting');
  _loadPage = this.loadPage.asObservable();

  params: any = {};
  dependencies: any = {};
  identifiers = [];

  constructor(
    private forgetService: forgetPasswordService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private helper: Helpers,
  ) { }

  ngOnInit() {
    this.params = this.route.snapshot.params;
    this.form = this.fb.group({
      new_password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6), ValidatorsUtil.isNumber()])),
      confirm_newPassword: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6), ValidatorsUtil.isNumber()])),
    });
    this.loading = '';
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.loadPage.next('loaded');
    }, 200);
  }

  onSubmit() {
    const transactionID = this.params.transactionID;
    const data = {
      transaction_id: transactionID,
      new_password: this.form.get('new_password').value,
    };
    this.forgetService.createPassword(data).subscribe(() => {
      this.helper.sweetAlert('Senha criada com sucesso', 'success', 'Sua conta foi desbloqueada.');
      localStorage.removeItem('transaction_id_unlock');
      setTimeout(() => {
        window.location.href = '/';
      }, 4000);
    }, (errors) => {
      const errResponse = errors.error;
      errResponse.errors.forEach(err => {
        switch (err.code) {
          case '022':
          case '003':
            this.helper.sweetAlert('Processo já foi finalizado', 'error', 'Refaça novamente o processo');
            break;
          case '005':
            this.helper.sweetAlert('Senha fraca', 'error', 'A senha não pode ser: a data de nascimento, sequencias númericas, números iguais, múltiplos de 10, começo ou final do documento registrado');
            break;
          default:
            this.helper.sweetAlert('Erro', 'error', 'Ocorreu um erro, entre em contato com a central.');
            break;
        }
      });
    });
  }

}
