import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { RouterModule, PreloadAllModules } from '@angular/router';

import { routes } from './app-routing.module';
import { LoginModule } from './login/login.module';
import { HeaderComponent } from './header/header.component';
import { RecoveryPasswordComponent } from './recovery-password/recovery-password.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { NgxMaskModule } from 'ngx-mask';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { forgetPasswordService } from './forget-password/forget-password.service';
import { GeneratePasswordComponent } from './generate-password/generate-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DesbloqueioContaComponent } from './desbloqueio-conta/desbloqueio-conta.component';
import { UnlockGeneratePasswordComponent } from './unlock-generate-password/unlock-generate-password.component';
import { getBasePath } from 'dharma-ui-common';
import { APP_BASE_HREF } from '@angular/common';
import { TrackerModule, NgxGoogleAnalyticsModule } from 'dharma-ui-analytics';
import { CpfIdentifierForgetPasswordComponent } from './cpf-identifier-forget-password/cpf-identifier-forget-password.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ChallengeModule } from './challenge/challenge.module';

const basePath = getBasePath('BASE_PATH_DEFAULT');
@NgModule({
  declarations: [	
    AppComponent,
    HeaderComponent,
    RecoveryPasswordComponent,
    ForgetPasswordComponent,
    GeneratePasswordComponent,
    ChangePasswordComponent,
    DesbloqueioContaComponent,
    UnlockGeneratePasswordComponent,
      CpfIdentifierForgetPasswordComponent
   ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: false, relativeLinkResolution: 'legacy' }),
    TrackerModule.forRoot({
      GTM_ID: 'GTM-PGML4SR',
      enablePageEvent: true,
    }),
    NgxGoogleAnalyticsModule.forRoot('UA-137514505-1'),
    LoginModule,
    ChallengeModule,
    NgxMaskModule.forRoot(),
    BrowserAnimationsModule,
  ],
  providers: [
    forgetPasswordService,
    { provide: APP_BASE_HREF, useValue: basePath },
    GoogleTagManagerService,
    DeviceDetectorService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
