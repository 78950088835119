import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Helpers } from '../shared/helpers';
import { forgetPasswordService } from '../forget-password/forget-password.service';
import { tap } from 'rxjs/operators';
import { LoadPageAnimation } from '../shared/animations';
import { SignupService } from '../services/signup.service';

@Component({
  selector: 'app-desbloqueio-conta',
  templateUrl: './desbloqueio-conta.component.html',
  styleUrls: ['./desbloqueio-conta.component.scss'],
  animations: [
    LoadPageAnimation
  ],
})
export class DesbloqueioContaComponent implements OnInit {

  loadPage = new BehaviorSubject('waiting');
  _loadPage = this.loadPage.asObservable();

  form: FormGroup;

  showModalSuccess: boolean = false;
  showSimpleModelReenvio: boolean = false;
  load: string = '';
  params: any = {};
  modalDependencies: any = {
    contactCentral: false,
    noBascicInformation: false,
  };
  dependencies: any = {};

  identifiers: any = [];
  responseError: string;

  constructor( 
    private route: ActivatedRoute,
    private router: Router,
    public helpers: Helpers, 
    private fb: FormBuilder,
    private forgetService: forgetPasswordService,
    private signupService: SignupService,
  ) { }

  ngOnInit() {
    localStorage.removeItem('transaction_id_unlock');
    this.params = this.route.snapshot.params;

    this.form = this.fb.group({
      identifier: [null, Validators.required],
    });

    const hasNoIdentifier = () => {
      this.modalDependencies.contactCentral = true;
    };

    this.load = 'show';
    this.forgetService.getIdentificadores(this.params.cpf).pipe(
      tap(() => this.load = ''),
      tap(identifiers => identifiers.length === 0 ? hasNoIdentifier() : null)
    ).subscribe(identifiers => this.identifiers = identifiers);
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.loadPage.next('loaded');
    }, 200);
  }

  sendTokenAgain(event) {
    this.load = 'show';

    this.signupService.sendTransaction(this.dependencies.transaction_id).subscribe(() => {
      this.load = '';
      this.showSimpleModelReenvio = true;
    }, err => {
      this.load = '';
      switch (err.error.code) {
        case '277':
          this.helpers.sweetAlert('Erro', 'error', 'Transação não encontrada.');
          break;
        default:
          this.helpers.sweetAlert('Erro', 'error', 'Ocorreu um erro, entre em contato com a central.');
          break;
      }
    });
  }

  onIdentifierSubmit() {
    this.load = 'show';
    const option_id = this.form.get('identifier').value;

    this.dependencies.modalType = option_id == 1 ? "email" : "sms";

    const options = {
      option_id: this.dependencies.modalType,
      document: this.params.cpf,
    };

    this.forgetService.unlock(options).pipe(
      tap(() => this.load = ''),
    ).subscribe((response: any) => {
      // abrir modal de token
      this.dependencies.optionsAPI = {
        transaction_id: response.transaction_id,
      };
      this.dependencies.transaction_id = response.transaction_id;
      this.dependencies.showTokenModal = true;
    }, (err) => {
      this.load = '';
      this.modalDependencies.contactCentral = true;
      this.helpers.sweetAlert('Desbloqueio da conta', '', null, this.defaultMessageOnError());
    });
  }

  defaultMessageOnError()
  {
    return '<br />Não foi possível desbloquear sua conta.<br />Entre em contato com nossa <strong>Central de Atendimento</strong><br />para poder desbloquear.<br /><br /><strong>4007 1339</strong><br />Para as principais capitais e regiões metropolitanas.<br /><br /><strong>0800 601 1339</strong><br />Para as demais localidades.<br /><br />Horário de atendimento: de segunda à sexta-feira, das 9h às 19h,<br />e sábado e domingo das 9h às 15h.'
  }

  onTokenSubmit(event) {
    if (event.valid === true) {
      this.router.navigate([`unlock-generate-password/${ this.params.cpf }/${this.dependencies.transaction_id}`]);
    }
  }

  getIdentifierSelected() {
    return this.identifiers.find(identifier => identifier.option_id === this.form.get('identifier').value);
  }

  redirectToLogin() {
    window.location.href = '../..';
  }

}
