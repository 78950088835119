import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './login/main/main.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { GeneratePasswordComponent } from './generate-password/generate-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DesbloqueioContaComponent } from './desbloqueio-conta/desbloqueio-conta.component';
import { UnlockGeneratePasswordComponent } from './unlock-generate-password/unlock-generate-password.component';
import { CpfIdentifierForgetPasswordComponent } from './cpf-identifier-forget-password/cpf-identifier-forget-password.component';
import { MainChallengeComponent } from './challenge/main/main.component';

export const routes: Routes = [
  {
    path: '',
    component: MainComponent
  },
  {
    path: 'login/:cpf',
    component: MainComponent
  },
  {
    path: 'validation/:cpf',
    component: MainComponent
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent,
  },
  {
    path: 'forget-password/:cpf',
    component: ForgetPasswordComponent,
  },
  {
    path: 'desbloquear-conta/:cpf',
    component: DesbloqueioContaComponent,
  },
  {
    path: 'unlock-generate-password/:cpf/:transactionID',
    component: UnlockGeneratePasswordComponent,
  },
  {
    path: 'generate-password/:cpf',
    component: GeneratePasswordComponent,
  },
  {
    path: 'change-password/:cpf',
    component: ChangePasswordComponent,
  },
  {
    path: 'challenge/:hash',
    component: MainChallengeComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }