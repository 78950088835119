import Swal from 'sweetalert2';
import { Injectable } from "@angular/core";

export interface AlertAttributes{
  icon: any;
  title: string;
}

@Injectable()
export class SwalErrorService {
    private setAlertAttributes(statusCode: number) : AlertAttributes
    {
      switch(statusCode) {
        case 400:
          return {icon: 'warning', title: 'Alerta'};
        default:
          return {icon: 'error', title: 'Erro'};
      }
    }
  
    public show(err) {
      const errors = err && err.error && err.error.errors || null;
      if (errors && errors.length > 0) {
        const list = document.createElement('ul');
        list.classList.add('no-bullets');
        errors.forEach(error => {
          const listItem = document.createElement('li');
          listItem.innerHTML = error.message;
          list.appendChild(listItem);
        });
        const attr = this.setAlertAttributes(err.status);
        return Swal.fire({
          icon: attr.icon,
          title: attr.title,
          html: list
        });
      }
      else {
        const attr = this.setAlertAttributes(0);
        return Swal.fire({
          icon: attr.icon,
          title: attr.title,
          html: 'Erro desconhecido.'
        });
      }
    }
}