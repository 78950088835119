import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class forgetPasswordService {

  constructor( private http: HttpClient ) { }

  /**
   * @description Pegar os identificadores 
   * @param { string } cpf 
   */
  getIdentificadores( cpf: string ): Observable<any>{
    return this.http.get(`${environment.SSO_DEFAULT_URI_ACCOUNTS}/v2/mfa/${cpf}`);
  }


  /**
   * @description Enviar o email ou sms para recuperar senha
   * @param { string } cpf 
   * @param { string } optionId 
   */
  sendForgetPassword(cpf, optionId) {
    return this.http.get(`${environment.SSO_DEFAULT_URI_ACCOUNTS}/v2/password/forgot/${optionId}/document/${cpf}`);
  }

  unlock(options) {
    return this.http.put(`${environment.SSO_DEFAULT_URI_ACCOUNTS}/v2/accounts/unlock`, options);
  }

  getUnconfirmedMFA(cpf) {
    return this.http.get(`${environment.SSO_DEFAULT_URI_ACCOUNTS}/v2/mfa/unconfirmed/${cpf}`);
  }

  changePassword(data: any = {}) {
    const sessionToken = localStorage.getItem('sessionToken');
    return this.http.put(`${environment.SSO_DEFAULT_URI_ACCOUNTS}/v2/password/change`, data, {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${sessionToken}`
      }),
    });
  }

  createTransactionChangePassword(optionId: any):Observable<any> {
    const sessionToken = localStorage.getItem('sessionToken');
    return this.http.get(`${environment.SSO_DEFAULT_URI_ACCOUNTS}/v2/password/change/${optionId}`, {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${sessionToken}`
      }),
    });
  }

  createPassword(options) {
    return this.http.post(`${environment.SSO_DEFAULT_URI_ACCOUNTS}/v2/password/create`, options);

  }
}
