import { Component, OnInit, AfterContentInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Helpers } from '../shared/helpers';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadPageAnimation } from '../shared/animations';
import { forgetPasswordService } from './forget-password.service';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
  animations: [
    LoadPageAnimation
  ],
})
export class ForgetPasswordComponent implements OnInit, AfterContentInit {


  loadPage = new BehaviorSubject('waiting');
  _loadPage = this.loadPage.asObservable();

  form: FormGroup;

  showModalSuccess: boolean = false;
  load: string = '';
  params: any = {};

  identifiers: any = [];
  responseError: string;
  helpDeskUri: string = environment.HELPDESK_URL;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public helpers: Helpers,
    private fb: FormBuilder,
    private forgetService: forgetPasswordService,
  ) { }

  ngOnInit() {
    this.redirectToSignup();
    this.params = this.route.snapshot.params;

    if (this.params.cpf.length == 11) {
      let buildURLToNewLogin = environment.NEW_LOGIN + '?identifier=' + this.params.cpf;
      const returnRoute = localStorage.getItem('urlAfterCallback');
      if (returnRoute) {
        buildURLToNewLogin = buildURLToNewLogin + '&urlAfterCallback=' + returnRoute;
      }
      window.open(buildURLToNewLogin, '_self');
    }

    this.form = this.fb.group({
      identifier: [null, Validators.required],
    });

    const hasNoIdentifier = () => {
      this.load = 'show';
      this.forgetService.getUnconfirmedMFA(this.params.cpf).subscribe(identifiers => {
        this.identifiers = identifiers;
        this.load = '';
      });
    };

    this.load = 'show';
    this.forgetService.getIdentificadores(this.params.cpf).pipe(
      tap(() => this.load = ''),
      tap(identifiers => identifiers.length === 0 ? hasNoIdentifier() : null)
    ).subscribe(identifiers => this.identifiers = identifiers);
  }

  redirectToSignup() {
    // redirecionar para signup
    const returnUrlAfterCallback = this.getReturnUrlCallback();
    const params = this.route.snapshot.params;

    if (params && !params.cpf) {
      if (returnUrlAfterCallback != '' && returnUrlAfterCallback != undefined) {
        var url_signup = `${environment.SIGNUP_UI_URI}forget-password-document?urlAfterCallback=${returnUrlAfterCallback}`;
        window.location.href = url_signup;
      } else {
        window.location.href = `${environment.SIGNUP_UI_URI}forget-password-document`;
      }
    }
    else {
      if (returnUrlAfterCallback != '' && returnUrlAfterCallback != undefined) {
        var url_signup = `${environment.SIGNUP_UI_URI}forget-password/${params.cpf}?urlAfterCallback=${returnUrlAfterCallback}`;
        window.location.href = url_signup;
      } else {
        window.location.href = `${environment.SIGNUP_UI_URI}forget-password/${params.cpf}`;
      }
    }
  }

  getReturnUrlCallback(): string {
    let returnRoute = localStorage.getItem('urlAfterCallback');
    return returnRoute != null && returnRoute != undefined ? returnRoute : '';
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.loadPage.next('loaded');
    }, 200);
  }

  onSubmit() {
    this.load = 'show';
    this.forgetService.sendForgetPassword(this.params.cpf, this.form.get('identifier').value).pipe(
      tap(() => this.load = ''),
    ).subscribe((response: any) => {
      if (response && response.success) {
        this.showModalSuccess = true;
      }
    }, (err) => {
      this.responseError = 'Ocorreu um erro, entre em contato com a central para mais informações';
    });
  }

  getIdentifierSelected() {
    return this.identifiers.find(identifier => identifier.option_id === this.form.get('identifier').value);
  }

  redirectToLogin() {
    window.location.href = '../..';
  }

}
