import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirstStepChallengeComponent } from './first-step/first-step.component';
import { SecondStepChallengeComponent } from './second-step/second-step.component';
import { SharedModule } from '../shared/shared.module';
import { MainChallengeComponent } from './main/main.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { Helpers } from '../shared/helpers';
import { RecaptchaModule } from 'ng-recaptcha';
import { RouterModule } from '@angular/router';
import { SwalErrorService } from '../shared/swal-errors.service';
import { CountdownTimerModule } from 'dharma-ui-components';
import { AlertModule, AlertSummaryModule } from 'dharma-ui-alerts';

@NgModule({
  declarations: [
    FirstStepChallengeComponent,
    SecondStepChallengeComponent,
    MainChallengeComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    RecaptchaModule,
    RouterModule,
    CountdownTimerModule,
    AlertModule.forChild(),
  ],
  providers: [
    SwalErrorService,
    Helpers,
  ],
})
export class ChallengeModule { }
