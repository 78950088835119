import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirstStepComponent } from './first-step/first-step.component';
import { SecondStepComponent } from './second-step/second-step.component';
import { SharedModule } from '../shared/shared.module';
import { MainComponent } from './main/main.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { Helpers } from '../shared/helpers';
import { RecaptchaModule } from 'ng-recaptcha';
import { AgreementsComponent } from './agreements/agreements.component';
import { RouterModule } from '@angular/router';
import { forgetPasswordService } from '../forget-password/forget-password.service';
import { ModalInfoContainerComponent } from './modal-info-container/modal-info-container.component';
import { SwalErrorService } from '../shared/swal-errors.service';

@NgModule({
  declarations: [
    FirstStepComponent,
    SecondStepComponent,
    MainComponent,
    AgreementsComponent,
    ModalInfoContainerComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    RecaptchaModule,
    RouterModule,
  ],
  providers: [
    SwalErrorService,
    Helpers,
    forgetPasswordService,
  ],
})
export class LoginModule { }
