import { Component, OnInit, AfterContentInit } from '@angular/core';
import { forgetPasswordService } from '../forget-password/forget-password.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Helpers } from '../shared/helpers';
import { BehaviorSubject } from 'rxjs';
import { ValidatorsUtil } from '../shared/validators.util';
import { LoadPageAnimation } from '../shared/animations';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SignupService } from 'src/app/services/signup.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  animations: [
    LoadPageAnimation
  ],
})
export class ChangePasswordComponent implements OnInit, AfterContentInit {

  form: FormGroup;
  formIdentifider: FormGroup;
  transactionID: string;
  showModalSuccess = false;
  showTokenModal = false;
  showModalSendMfa = false;
  showModalSMSEmail = false;
  showSimpleModelReenvio = false;
  loading = '';
  responseError;

  loadPage = new BehaviorSubject('waiting');
  _loadPage = this.loadPage.asObservable();

  params: any = {};
  dependencies: any = {};
  identifiers = [];

  constructor(
    private forgetService: forgetPasswordService,
    private fb: FormBuilder,
    private helper: Helpers,
    private route: ActivatedRoute,
    private service: SignupService
  ) { }

  ngOnInit() {
    this.params = this.route.snapshot.params;
    this.form = this.fb.group({
      old_password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6), ValidatorsUtil.isNumber()])),
      new_password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6), ValidatorsUtil.isNumber()])),
      confirm_newPassword: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6), ValidatorsUtil.isNumber()])),
    });
    this.formIdentifider = this.fb.group({
      identifier: [null, Validators.required],
    });

    this.dependencies.sessionToken = localStorage.getItem('sessionToken');
    this.loading = '';
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.loadPage.next('loaded');
    }, 200);
  }

  onSubmit() {
    this.changePassword();
  }

  sendTokenAgain(event) {
    this.loading = 'show';

    this.service.sendTransaction(this.dependencies.option_id).subscribe(() => {
      this.loading = '';
      this.showSimpleModelReenvio = true;
    }, err => {
      this.loading = '';
      switch (err.error.code) {
        case '277':
          this.helper.sweetAlert('Erro', 'error', 'O processo atingiu o número máximo de reenvios.');
          break;
        default:
          this.helper.sweetAlert('Erro', 'error', 'Ocorreu um erro, entre em contato com a central.');
          break;
      }
    });
  }

  onTokenSubmit(event) {
    this.changePassword();
  }

  /**
   * @description Pegar os identificadores
   * @param { string } cpf
   */
  getIdentificadores(cpf: string): Observable<any> {
    return this.forgetService.getIdentificadores(cpf);
  }

  changePassword() {
    let data = {
      ...this.form.value,
    };

    if (this.dependencies.transaction_id) {
      data.transaction_id = this.dependencies.transaction_id;
    }
    delete data.confirm_newPassword;
    this.loading = 'show';
    this.forgetService.changePassword(data).subscribe((response: any) => {
      this.loading = '';
      if (parseInt(response.data.option_id))
        this.dependencies.modalType = response.data.option_id == 1 ? 'email' : 'sms';
      else
        this.dependencies.modalType = response.data.option_id;

      if (response && response.data.transaction_id) {
        // abrir modal de token
        this.dependencies.optionsAPI = {
          transaction_id: response.data.transaction_id,
        };
        this.dependencies.transaction_id = response.data.transaction_id;
        this.dependencies.showTokenModal = true;
        this.showModalSMSEmail = false;
      } else {
        this.helper.sweetAlert('Senha alterada com sucesso', 'success', 'Sua senha foi alterada com sucesso');
        setTimeout(() => {
          const token = localStorage.getItem('sessionToken');

          let returnUrlHttps = this.injectHttps(this.getReturnRoute());

          let url = environment.SITE_DOTZ + `/DynamicData.aspx?originurl=${returnUrlHttps}&ffac79874009f401f68532cf47633=${token}`;

          if (this.existsQuizNotFound())
            url += '&dados_incompletos=true';

          window.location.href = url;
        }, 3000);
      }
    }, (err) => this.printError(err, this));
  }

  loadConfirmTransaction(scope: ChangePasswordComponent) {
    scope.loading = 'show';
    scope.getIdentificadores(scope.params.cpf).pipe(
      tap(() => scope.loading = ''),
    ).subscribe(identifiers => {
      scope.identifiers = identifiers;
      scope.showModalSendMfa = true;
    });
  }

  onIdentifierSubmit() {
    this.loading = 'show';
    const option_id = this.formIdentifider.get('identifier').value;

    this.dependencies.modalType = option_id == 1 ? "email" : "sms";

    this.forgetService.createTransactionChangePassword(option_id).pipe(
      tap(() => this.loading = ''),
    ).subscribe(response => {
      // abrir modal de token
      this.dependencies.optionsAPI = {
        transaction_id: response.data.transaction_id,
      };
      this.dependencies.transaction_id = response.data.transaction_id;
      this.dependencies.showTokenModal = true;
    }, (err) => this.printError(err, this));
  }

  printError(err: any, scope: ChangePasswordComponent) {
    scope.loading = '';

    if (err.error && err.error.errors) {
      err.error.errors.forEach(error => {
        switch (error.code) {
          case '005'://Senha fraca
            this.helper.sweetAlert('Senha fraca', 'error', 'A senha não pode ser: a data de nascimento, sequencias númericas, números iguais, múltiplos de 10, começo ou final do documento registrado');
            break;
          case '007'://Senha atual não está válida
            scope.helper.sweetAlert('Erro', 'error', error.message);
            break;
          case '003'://Transação inválida
            if (!(scope.dependencies && scope.dependencies.option_id && scope.dependencies.option_id.transaction_id)) {
              scope.loadConfirmTransaction(scope);
              break;
            }
          default:
            scope.helper.sweetAlert('Erro', 'error', 'Ocorreu um erro, repita novamente o processo.');
            // console.log('err', err);
            break;
        }
      });
    }
    else {
      scope.helper.sweetAlert('Erro', 'error', 'Ocorreu um erro, repita novamente o processo.');
      //console.log('err', err);
    }
  }

  getReturnRoute(): string {
    let returnRoute = localStorage.getItem('rota');
    return returnRoute !== null && returnRoute !== undefined ? returnRoute : '';
  }

  existsQuizNotFound(): boolean {
    const item = localStorage.getItem('isQuizNotFound');

    return item !== null && item != undefined;
  }

  injectHttps(url: string) {
    if (url.indexOf('https') === -1) {
      return url.replace(/^http:\/\//i, 'https://');
    }
    return url;
  }
}
