<app-step-box>
  <div [@loadPage]="loadPage.getValue()">
    <h1>Verificamos que você ainda não possui uma senha</h1>

    <p>Escolha um identificador para receber sua nova senha</p>

    <div class="form">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
        <div class="input-wrapper">
          <div class="input-wrapper" *ngFor="let identifierItem of identifiers">
            <div class="dz-custom-radio">
              <input type="radio" name="identifier" id="{{ identifierItem?.option_id }}-radio" [value]="identifierItem?.option_id" formControlName="identifier">
              <label for="{{ identifierItem?.option_id }}-radio"></label>
            </div>
            <div class="text">
              <label for="phone">
                {{ identifierItem.option_id === 1 ? 'email' : 'Telefone' }} <br>
                {{ identifierItem?.value }}
              </label>
            </div>
          </div>
        </div>

        <div class="text-center">
            <strong class="error-msg" *ngIf="responseError">*{{ responseError }}</strong>
        </div>

        <div class="cta-wrapper">
          <app-btn color="orange" type="submit" text="Gerar uma senha temporária" size="default"
            [disabled]="!form.get('identifier').valid">
          </app-btn>
        </div>
      </form>
    </div>
  </div>
</app-step-box>



<app-simple-modal [showModal]="showModalSuccess">
  <div class="contact-central" *ngIf="showModalSuccess">
    <span class="close-modal" (click)="redirectToLogin()">X</span>

    <h1 *ngIf="getIdentifierSelected().option_id === 1">Geramos uma nova senha e enviamos para o email selecionado</h1>
    <h1 *ngIf="getIdentifierSelected().option_id === 2">Geramos uma nova senha e enviamos um sms para o celular selecionado</h1>
    <p>
      <span *ngIf="getIdentifierSelected().option_id === 1">Verifique o email:</span>
      <span *ngIf="getIdentifierSelected().option_id === 2">Verifique o celular:</span>

      <br/> 
      {{ getIdentifierSelected().value }}
      <br/>
      para obter sua senha
    </p>

  </div>

</app-simple-modal>




<app-load [option]="load"></app-load>