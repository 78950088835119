import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SignupService } from 'src/app/services/signup.service';
import { tap, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { forgetPasswordService } from 'src/app/forget-password/forget-password.service';
import { Helpers } from 'src/app/shared/helpers';
import { SwalErrorService } from 'src/app/shared/swal-errors.service';

@Component({
  selector: 'app-main-challenge',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainChallengeComponent implements OnInit {

  load: string = '';

  // Steps
  showFirst: boolean = true;
  showSecond: boolean = false;

  hash: string = '';
  identifierType: string = '';
  tokenMinutesExpiration = 60000;

  constructor(
    private service: SignupService,
    private router: Router,
    private route: ActivatedRoute,
    private helper: Helpers,
    private swalErrorService: SwalErrorService) {
  }

  ngOnInit() {
    this.hash = this.route.snapshot.params?.hash;

    this.showFirst = true;
    this.showSecond = false;
  }


  firstStep(e: any) {
    if(e.valid) {
      this.showFirst = false;
      this.showSecond = true;
      this.identifierType = e.identifierType;
      this.tokenMinutesExpiration = e.tokenMinutesExpiration;
    }
  }

  secondStep(e: any) {
    
  }
}
