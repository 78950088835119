import { Component, OnInit, Input, NgZone, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent implements OnInit {


  @Output() onFinish = new EventEmitter();

  @Input() timeLeft: number = 180000;

  currentTime: number;
  displayTime: string;

  showCircle: boolean = false;
  animationStyle: any;

  constructor(
    private zone: NgZone,
  ) { }

  ngOnInit() {
    this.start();
    this.showCircle = true;
    this.animationStyle = { animation : `countdown ${((+this.timeLeft) / 1000).toString()}s linear infinite forwards;`};
  }

  start() {
    this.currentTime = +this.timeLeft;
    setInterval(() => {
      this.currentTime = this.currentTime > 0 ? this.currentTime - 1000 : 0;
      this.displayTimeFunction(this.currentTime);
      if (this.currentTime === 0) {
        this.onFinish.emit(true);
      }
    }, 1000);
  }

  startAgain() {
    this.currentTime = this.timeLeft;
    this.showCircle = false;
    setTimeout(() => {
      this.showCircle = true;
    }, 100);
  }

  displayTimeFunction(currentTime) {
    this.zone.run(() => {
      const tempTime = moment.duration(currentTime);
      if (tempTime.minutes() > 0) {
        this.displayTime = `${tempTime.minutes()} minutos ${ tempTime.seconds() } segundos`;
      } else {
        this.displayTime = `${ tempTime.seconds() } segundos`;
      }
    });
  }

}
