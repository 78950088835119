<app-step-box>
  <div [@loadPage]="loadPage.getValue()">
    <h1>Desbloqueio de conta</h1>

    <p>Escolha um identificador para receber o token de validação</p>

    <div class="form">
      <form [formGroup]="form" (ngSubmit)="onIdentifierSubmit()" autocomplete="off">
        <div class="input-wrapper">
          <div class="input-wrapper" *ngFor="let identifierItem of identifiers">
            <div class="dz-custom-radio">
              <input type="radio" name="identifier" id="{{ identifierItem?.option_id }}-radio"
                [value]="identifierItem?.option_id" formControlName="identifier">
              <label for="{{ identifierItem?.option_id }}-radio"></label>
            </div>
            <div class="text">
              <label for="phone">
                {{ identifierItem.option_id === 1 ? 'email' : 'Telefone' }} <br>
                {{ identifierItem?.value }}
              </label>
            </div>
          </div>
        </div>

        <div class="text-center">
          <strong class="error-msg" *ngIf="responseError">*{{ responseError }}</strong>
        </div>

        <div class="cta-wrapper">
          <app-btn color="orange" type="submit" text="Desbloquear" size="default"
            [disabled]="!form.get('identifier').valid">
          </app-btn>
        </div>
      </form>
    </div>
  </div>
</app-step-box>


<app-modal-token [enableSkip]="false" [cpf]="params && params.cpf ? params.cpf : null" [type]="dependencies.modalType" [showModal]="dependencies.showTokenModal"
  [optionsAPI]="dependencies.optionsAPI" [transactionid]="dependencies.transaction_id" (response)="onTokenSubmit($event)" (sendTokenAgain)="sendTokenAgain($event)"
  (clearTransactionID)="dependencies.optionsAPI.transaction_id = null;">
  <p *ngIf="dependencies.modalType == 'sms'">
    Um código de validação foi enviado <br>
    para o número de telefone que você informou. <br>
    Insira abaixo.
  </p>

  <p *ngIf="dependencies.modalType == 'email'">
    Um <strong>código de validação</strong> foi enviado para <br>
    o e-mail cadastrado e ficará disponível por 24h. <br>
    Digite-o abaixo:
  </p>
</app-modal-token>

<app-load [option]="load"></app-load>