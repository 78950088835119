<div [@loadPage]="loadPage.getValue()">
  <h1>Confirmar dispositivo</h1>

  <p>Escolha um identificador para confirmar o dispositivo</p>

  <div class="form">
    <form [formGroup]="firstStepForm" (ngSubmit)="onIdentifierSubmit()" autocomplete="off">

      <div class="col-sm-12 identifiers text-center">
        <div class="form-check" *ngFor="let identifierItem of identifiers">
          <label>
            <input type="radio" name="identifier" id="{{ identifierItem?.type }}-radio" [value]="identifierItem?.type" formControlName="identifier"> 
            <span class="label-text">
              {{ identifierItem?.identifier }}
            </span>
          </label>
        </div>
      </div>

      <div class="cta-wrapper">
        <app-btn color="orange" type="submit" text="Confirmar" size="default"
          [disabled]="!firstStepForm.get('identifier').valid">
        </app-btn>
      </div>
    </form>
  </div>
</div>

<app-load [option]="load"></app-load>