import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class ValidatorsUtil {

  static errorMessages = {
    lessThanControlValue: (controlName: string) => `O valor deste formControl não deve ser menor que o valor do control filho ${controlName}`,
    childShouldNotBeEmpty: (controlName: string) => `O formArray filho ${controlName} não deve estar vazio`,
    atLeastOneChildShouldNotBeEmpty: (controlNames: string[]) => `O(s) formArray(s) filho(s) ${controlNames.toString()} não deve(m) estar vazio(s)`,
    isNumber: () => 'O valor deste formControl não deve ser vazio',
    differentThanZero: () => 'O valor deste formControl deve ser superior à zero',
    positiveNumber: () => 'O valor deste formControl deve ser positivo',
    positiveNumberOrZero: () => 'O valor deste formControl deve ser positivo ou zero',
    checkName: () => 'Não é permitido números',
    isSiteValid: () => 'Site inválido',
  };

  static getError(errorName: string, controlNames?: string | string[]): ValidationErrors {
    const error = {};
    const errorMessage = ValidatorsUtil.errorMessages[errorName](controlNames);
    error[errorName] = errorMessage;
    return error;
  }

  static isNumber(error?: ValidationErrors): ValidatorFn {
    error = error || ValidatorsUtil.getError('isNumber');
    return (control: AbstractControl): ValidationErrors => !isNaN(+control.value) ? null : error;
  }

  static differentThanZero(error?: ValidationErrors): ValidatorFn {
    error = error || ValidatorsUtil.getError('differentThanZero');
    return (control: AbstractControl): ValidationErrors => +control.value !== 0 ? null : error;
  }

  static positiveNumber(error?: ValidationErrors): ValidatorFn {
    error = error || ValidatorsUtil.getError('positiveNumber');
    return (control: AbstractControl): ValidationErrors => +control.value > 0 ? null : error;
  }

  static positiveNumberOrZero(error?: ValidationErrors): ValidatorFn {
    error = error || ValidatorsUtil.getError('positiveNumberOrZero');
    return (control: AbstractControl): ValidationErrors => +control.value >= 0 ? null : error;
  }

  static isSiteValid(error?: ValidationErrors): ValidatorFn {
    error = error || ValidatorsUtil.getError('isSiteValid');
    var pattern = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

    return (control: AbstractControl): ValidationErrors => {
      return pattern.test(control.value) ? null : error;
    }
  }

  static checkName(error?: ValidationErrors): ValidatorFn {
    error = error || ValidatorsUtil.getError('checkName');
    return (control: AbstractControl): ValidationErrors => {
      const name = control.value;
      const patt = /[0-9]|[\"\!\@\$\%\&\*\(\)\_\-\=\+]/g;
      return patt.test(name) ? error : null;
    }
  }

  static childShouldNotBeEmpty(controlName: string, error?: ValidationErrors): ValidatorFn {
    error = error || ValidatorsUtil.getError('childShouldNotBeEmpty', controlName);

    return (control: AbstractControl): ValidationErrors => (+control.get(controlName).value.length) ? null : error;
  }

  static atLeastOneChildShouldNotBeEmpty(controlNames: string[], error?: ValidationErrors): ValidatorFn {
    error = error || ValidatorsUtil.getError('atLeastOneChildShouldNotBeEmpty', controlNames);

    return (control: AbstractControl): ValidationErrors => {
      return controlNames
        .map(controlName => control.get(controlName).value.length)
        .some(length => <boolean>length) ? null : error;
    };
  }
}
