import { Component, OnInit, Output, EventEmitter, AfterContentInit, Input, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { LoadPageAnimation } from 'src/app/shared/animations';
import { SignupService } from 'src/app/services/signup.service';
import { LoginDataModel } from 'src/app/services/models/loginData.model';
import { Router } from '@angular/router';
import { forgetPasswordService } from 'src/app/forget-password/forget-password.service';
import { tap } from 'rxjs/operators';
import { Helpers } from 'src/app/shared/helpers';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from "ngx-device-detector";
import * as Cookies from 'js-cookie';
import { sha256 } from 'js-sha256';
import { DeviceChannel } from 'src/app/shared/enum/device-channel';
import { OfdbUtilsService } from 'src/app/services/ofdb-utils.service';
import { DeviceMapperService } from 'src/app/services/device-mapper.service';

@Component({
  selector: 'app-second-step',
  templateUrl: './second-step.component.html',
  styleUrls: ['./second-step.component.scss'],
  animations: [
    LoadPageAnimation
  ]
})
export class SecondStepComponent implements OnInit, AfterContentInit {

  @ViewChild('birthday') birthdayInput: ElementRef;

  loadPage = new BehaviorSubject('waiting');
  _loadPage = this.loadPage.asObservable();
  
  secondForm: FormGroup;

  @Input() userData: LoginDataModel;
  @Input() cpf: string = '';
  @Output() response = new EventEmitter();

  userFullName: string = '';
  password: string = '';
  dateValidation: string = '';
  virtualKeyboard: Array<any> = [];
  identifiers = [];

  load: string = '';
  msgError: string = '';
  showEsqueciMinhaSenha: boolean = true;
  showSimpleModal: boolean = false;
  acessToken: string;

  
  helpDeskUri: string = environment.HELPDESK_URL;
  showhelpDeskUri: boolean =false;

  modalDependencies: any = {
    contactCentral: false,
    unlockAccount: false,
  };

  constructor( 
    private service: SignupService, 
    private router: Router, 
    private forgetPasswordService: forgetPasswordService,
    private helper: Helpers,
    private deviceService: DeviceDetectorService,
    private deviceMapper: DeviceMapperService,
    private ofdbUtilsService: OfdbUtilsService,
  ) { }

  ngOnInit() {
    // passar session ID em SHA256
    const date = new Date().toISOString();
    const session = sha256(date);
    this.deviceMapper.save('session_id', session);

    this.ofdbUtilsService.ofdbInit(this.cpf)
    .subscribe((result) => {
      console.log(result);
    });
 

    this.userData['cpf'] = this.cpf;
    this.secondForm = new FormGroup({
      password: new FormControl({value:'', disabled: true}, [Validators.required, Validators.maxLength(6), Validators.minLength(6)]),
      birthday: new FormControl('', [Validators.required, Validators.pattern('[0-9]{2}')]),
      recaptcha: new FormControl('')
    });

    this.secondForm.get('password').valueChanges.subscribe(() => {
      const value = this.secondForm.get('password').value;
      if (value && value.length === 6) {
        window.scrollTo(0, document.body.scrollHeight);
        this.birthdayInput.nativeElement.focus();
      }
    });

    if ( this.userData.isRecaptchaActive ) {
      this.secondForm.get('recaptcha').setValidators( Validators.required );
    }

    this.virtualKeyboard = [];

    if( Object.keys( this.userData ).length > 0 ) {
      this.virtualKeyboard.push(`${this.userData.virtualKeyboard[0]} ou ${this.userData.virtualKeyboard[1]}`);
      this.virtualKeyboard.push(`${this.userData.virtualKeyboard[2]} ou ${this.userData.virtualKeyboard[3]}`);
      this.virtualKeyboard.push(`${this.userData.virtualKeyboard[4]} ou ${this.userData.virtualKeyboard[5]}`);
      this.virtualKeyboard.push(`${this.userData.virtualKeyboard[6]} ou ${this.userData.virtualKeyboard[7]}`);
      this.virtualKeyboard.push(`${this.userData.virtualKeyboard[8]} ou ${this.userData.virtualKeyboard[9]}`);

      this.userFullName = this.userData.firstName;

      switch( this.userData.dateValidation ){
        case 0:
          this.dateValidation = 'DIA';
          break;
        case 1:
          this.dateValidation = 'MÊS';
          break;
        case 2:
          this.dateValidation = 'ANO ( Os dois últimos dígitos )';
          break;
        default:
          break;
      }
    }

    this.forgetPasswordService.getIdentificadores(this.cpf).pipe(
      tap(identifiers => this.identifiers = identifiers),
    ).subscribe();
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.loadPage.next('loaded');
    }, 200);
  }

  refreshPage():void {
    window.location.reload()
  }

  onSubmit() {

    this.load = 'show';
    this.showhelpDeskUri = false;
 
    const loginData = new FormData();
    loginData.append('grant_type', 'password');
    //loginData.append('scope', 'accounts.api members.api offers.api multicampaign.api clubedotz.api');
    loginData.append('scope', environment.SSO_CLIENTID_SCOPES);
    loginData.append('username', this.cpf);
    loginData.append('password', btoa( this.secondForm.get('password').value ) );
    loginData.append('birthday', this.secondForm.get('birthday').value );
    loginData.append('recaptcha', this.secondForm.get('recaptcha').value );
    loginData.append('channel', (this.deviceService.isDesktop()
                                    ? DeviceChannel.DesktopBrowser
                                    : DeviceChannel.MobileBrowser).toString());
    loginData.append('sessionId', this.deviceMapper.get().session_id );
    
    this.ofdbUtilsService.getSyncID((sync) => {
      loginData.append('syncId', sync );
      this.service.login( loginData ).subscribe(response => {
        this.load = '';
  
        var hash_challenge = response?.Data?.Hash ?? response?.data?.hash;
        hash_challenge = hash_challenge ??  response?.data?.data?.hash;
        
        if(response.access_token === undefined && (hash_challenge !== undefined || hash_challenge !== undefined)) {
          this.response.emit({valid: false, challenge: true, hash: hash_challenge});
        }
        else {
          Cookies.set('dotzSessionToken', response.access_token);
          localStorage.setItem('sessionToken', response.access_token);
          const isTemporaryPassword = localStorage.getItem('isTemporaryPassword');
          if (isTemporaryPassword === 'true') {
            this.router.navigate([`change-password/${ this.cpf }`]);
            return;
          }
          this.response.emit({valid: true, token: response.access_token });
        }
      }, err => {
        this.userData.isRecaptchaActive = true;
        this.msgError = err.error?.message;

        if(Array.isArray(err.error?.errors)) 
        {
            switch(err.error?.errors[0].code) {
              case '353': 
                this.msgError = this.msgError ?? err.error?.errors[0]?.message;
                this.showhelpDeskUri = true;
                break;
            }
        }
 
        this.secondForm.setErrors({ invalid: true });
        this.load = '';
        
        switch(err.error?.code) {
          case '041':
            let hasIdentifier = this.identifiers && this.identifiers.length > 0;
            
            this.modalDependencies.contactCentral = !hasIdentifier;
            this.modalDependencies.unlockAccount = hasIdentifier;
  
            this.showSimpleModal = true;
            this.helper.showContactModal.bind(this);
            this.modalDependencies.responseError = 'Conta Bloqueada';
            
            break;
        }
      });
    }, err => {
      console.log(err);
    })
    

  }

  setPass( e: any ) {

    if ( this.password.length < 6 ) {
      this.password += e;
    }
    this.secondForm.get('password').setValue( this.password );

  }

  clearPassword() {
    this.password = '';
    this.secondForm.get('password').reset();
    this.secondForm.setErrors({'invalid': true});
  }

  resolveCaptcha( response: any ) {
    this.secondForm.get('recaptcha').setValue( response );
  }

  forgetPassword() {
    this.router.navigate([`forget-password/${ this.cpf }`]);
  }

}
