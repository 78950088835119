<app-step-box>
  <app-first-step-challenge [hash]="hash" (response)="firstStep($event)" *ngIf="showFirst"></app-first-step-challenge>
  <app-second-step-challenge 
  [tokenMinutesExpiration]="tokenMinutesExpiration" 
  [hash]="hash" 
  [identifierType]="identifierType" 
  (response)="secondStep($event)" 
  (returnPage)="ngOnInit()"
  *ngIf="showSecond"></app-second-step-challenge>
</app-step-box>

<app-load [option]="load"></app-load>