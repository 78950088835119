import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetIdentifiersConfirmModel } from './models/getIdentifiersConfirm.model';
import { GetChallengeFinishModel } from './models/getChallengeFinish.model';
import { PutChallengeSendCodeModel } from './models/putChallengeSendCode.model';

@Injectable({
  providedIn: 'root'
})

export class TransactionValidationService {

  constructor(private http: HttpClient) { }


  getIdentifiersConfirm(hash: string): Observable<GetIdentifiersConfirmModel> {
    return this.http.get<GetIdentifiersConfirmModel>(`${environment.API_TRANSACTIONVALIDATION_DEFAULT}/challenge/${hash}/identifiers`,
      {
        headers: { Authorization: `Basic ${environment.SSO_CLIENT_ID}` }
      });
  }

  putSendCode(hash: string, optionId: number) : Observable<PutChallengeSendCodeModel> {
    return this.http.put<PutChallengeSendCodeModel>(`${environment.API_TRANSACTIONVALIDATION_DEFAULT}/challenge/${hash}/option/${optionId}`, {},
      {
        headers: { Authorization: `Basic ${environment.SSO_CLIENT_ID}` }
      });
  }

  putResendCode(hash: string) {
    return this.http.put(`${environment.API_TRANSACTIONVALIDATION_DEFAULT}/challenge/${hash}/resend`, {},
      {
        headers: { Authorization: `Basic ${environment.SSO_CLIENT_ID}` }
      });
  }

  putConfirm(hash: string, code: string) {
    return this.http.put(`${environment.API_TRANSACTIONVALIDATION_DEFAULT}/challenge/${hash}/confirm/${code}`, {},
      {
        headers: { Authorization: `Basic ${environment.SSO_CLIENT_ID}` }
      });
  }

  getChallengeFinish(hash: string, channel: string): Observable<GetChallengeFinishModel> {
    return this.http.get<GetChallengeFinishModel>(`${environment.SSO_DEFAULT_URI_ACCOUNTS}/challenge/${hash}/GetChallengeStatus/${channel}`,
      {
        headers: { Authorization: `Basic ${environment.SSO_CLIENT_ID}` }
      });
  }
}
